/* eslint-disable camelcase */
import { ValidationOperator } from '../validationRules'
import { QuestionBase, QuestionType, RemoteQuestionBase } from './base'

export enum DateTimeValidationRuleOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  Lower = 'LOWER',
  Greater = 'GREATER',
  MoreThanBefore = 'MORE_THAN_BEFORE',
  LessThanBefore = 'LESS_THAN_BEFORE',
  LessThanAfter = 'LESS_THAN_AFTER',
  MoreThanAfter = 'MORE_THAN_AFTER'
}

export const operatorsWithoutAmount = [
  ValidationOperator.Greater,
  ValidationOperator.Lower,
  ValidationOperator.Equal,
  ValidationOperator.NotEqual
]

export enum DateTimePeriodType {
  Days = 'DAYS',
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Years = 'YEARS',
  Months = 'MONTHS'
}

export enum DateTimeSubtype {
  Date = 'DATE',
  Time = 'TIME',
  DateTime = 'DATETIME',
  Year = 'YEAR',
  YearMonth = 'YEAR_MONTH',
  SwitchableDate = 'SWITCHABLE_DATE'
}

export interface RemoteDateTimeQuestion extends RemoteQuestionBase {
  type: QuestionType.DateTime
  config: {
    subtype: DateTimeSubtype
    disable_future_datetime: boolean
  }
}

export interface DateTimeQuestion extends QuestionBase {
  type: QuestionType.DateTime
  config: {
    subtype: DateTimeSubtype
    disableFutureDatetime: boolean
  }
}

export const prepareDateTimeQuestionConfigForSave = (config: DateTimeQuestion['config']) => {
  return {
    subtype: config.subtype,
    disable_future_datetime: config.disableFutureDatetime
  }
}

export const parseDateTimeTypeRemoteQuestionConfig = (config: RemoteDateTimeQuestion['config']) => ({
  subtype: config.subtype,
  disableFutureDatetime: config.disable_future_datetime
})
