import './DatacStatusTag.less'

import classNames from 'classnames'
import React from 'react'

import { useScopedIntl } from '../../hooks'
import { DatacTooltip } from '../DatacTooltip'

interface DatacStatusTag {
  status: string
  config: {
    [key: string]: { className: string; label: string }
  }
  className?: string
  tooltip?: boolean
  label?: string
  noDot?: boolean
}

interface Props extends React.FC<DatacStatusTag> {
  Indicator: React.FC<DatacStatusTag>
  Label: React.FC<DatacStatusTag>
}

export const DatacStatusTag: Props = ({ status, className, config, tooltip, label, noDot }) => {
  return (
    <div className={classNames('status-tag', className)}>
      {!noDot && <DatacStatusTag.Indicator status={status} config={config} tooltip={tooltip} />}
      <DatacStatusTag.Label label={label} status={status} config={config} />
    </div>
  )
}

DatacStatusTag.Label = ({ status, className, config, label }) => {
  if (!status) return null

  const intl = useScopedIntl('')
  const statusLabel = config?.[status]?.label
  const translation = statusLabel ? intl(statusLabel) : ' '

  return <span className={classNames('status-tag__label', className)}>{label || translation}</span>
}

DatacStatusTag.Indicator = ({ status, config, tooltip }) => {
  if (!status) return null

  const intl = useScopedIntl('')
  const label = config?.[status]?.label
  const translation = label ? intl(label) : ' '

  return (
    <div className="status-indicator">
      <DatacTooltip title={tooltip && translation}>
        <div className={classNames('status-indicator__content', config?.[status]?.className)}>&nbsp;</div>
      </DatacTooltip>
    </div>
  )
}
