import DOMPurify from 'dompurify'
import React from 'react'
import { useIntl } from 'react-intl'

const getMessageId = (scope: string | string[], scopedId: string) =>
  scope ? `${Array.isArray(scope) ? scope.join('.') : scope}.${scopedId}` : scopedId

export const useScopedIntl = (scope: string | string[]) => {
  const intl = useIntl()
  return (scopedId: string, values?: Record<string, string | number>) =>
    intl.formatMessage({ id: getMessageId(scope, scopedId) }, values)
}

export const useScopedIntlWithTags = (scope: string | string[]) => {
  const intl = useIntl()
  return (scopedId: string, values?: Record<string, string | number>) => {
    const message = intl.formatMessage({ id: getMessageId(scope, scopedId) }, values, { ignoreTag: false })

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(message, {
            ALLOWED_TAGS: ['b', 'i', 'br']
          })
        }}
      />
    )
  }
}
