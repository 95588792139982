/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../utils'
import { BackendError } from '../RequestError'
import { DataMapping, fetchApi, mapData } from '../fetchApi'
import {
  Block,
  ConditionalRule,
  QuestionType,
  RemoteBlock,
  RemoteConditionalRule,
  parseRemoteBlock,
  parseRemoteConditionalLogic,
  prepareQuestionConditionalLogicForSave
} from './blocks'

export interface RemoteSubsection {
  id: string
  name: string
  domain: string
  order: number
  excluded_roles_ids: number[]
  blocks?: RemoteBlock[]
  conditional_logic?: RemoteConditionalRule
  invalid: boolean
}

export interface RemoteSection {
  id: string
  name: string
  domain: string
  order: number
  excluded_roles_ids: number[]
  variable_prefix?: string
  variable_suffix?: string
  subsections: RemoteSubsection[]
  conditional_logic?: RemoteConditionalRule
  invalid: boolean
}

export interface RemoteStructure {
  sections: RemoteSection[]
}

export interface Structure {
  sections: Section[]
}

export interface Section {
  id?: string
  tempId?: string
  name: string
  domain: string
  variablePrefix?: string
  variableSuffix?: string
  excludedRolesIds: string[]
  subsections: Subsection[]
  conditionalLogic?: ConditionalRule[]
}

export interface Subsection {
  id?: string
  tempId?: string
  name: string
  domain: string
  excludedRolesIds: string[]
  blocks?: Block[]
  conditionalLogic?: ConditionalRule[]
}

const parseRemoteRole = (roleId: number) => roleId.toString()
const parseRoleForSave = (roleId: string) => parseInt(roleId, 10)

export const parseRemoteStructure = (structure: RemoteStructure) => ({
  sections: structure.sections.map(parseRemoteSection)
})

export const parseRemoteSection = (section: RemoteSection) => ({
  ...section,
  id: section.id && section.id.toString(),
  name: section.name,
  domain: section.domain,
  excludedRolesIds: section.excluded_roles_ids?.map(parseRemoteRole),
  variablePrefix: section.variable_prefix,
  variableSuffix: section.variable_suffix,
  subsections: section.subsections.map(parseRemoteSubsection),
  conditionalLogic: parseRemoteConditionalLogic(section.conditional_logic)
})

const parseRemoteSubsection = (subsection: RemoteSubsection) => ({
  id: subsection.id && subsection.id.toString(),
  name: subsection.name,
  domain: subsection.domain,
  blocks: subsection.blocks?.map(parseRemoteBlock),
  excludedRolesIds: subsection.excluded_roles_ids?.map(parseRemoteRole),
  conditionalLogic: parseRemoteConditionalLogic(subsection.conditional_logic)
})

const prepareStructureForSave = (structure: Structure) => ({
  sections: structure.sections.map((section, i) => ({
    id: section.id || undefined,
    name: section.name,
    excluded_roles_ids: section.excludedRolesIds?.map(parseRoleForSave),
    order: i,
    subsections: section.subsections.map((subsection, j) => ({
      id: subsection.id || undefined,
      name: subsection.name,
      excluded_roles_ids: subsection.excludedRolesIds?.map(parseRoleForSave),
      order: j
    }))
  }))
})

interface RemoteStructureSummary {
  study: string
  sections: {
    id: number
    name: string
    order: number
    subsections: {
      id: number
      name: string
      order: number
      blocks: {
        id: number
        title: string
        order: number
        variable?: string
      }[]
    }[]
  }[]
}

export interface StructureSummary {
  studyId: string
  sections: {
    id: string
    name: string
    subsections: {
      id: string
      name: string
      blocks: {
        id: string
        title: string
        variableName?: string
      }[]
    }[]
  }[]
}

export const parseRemoteStructureSummary = (remoteSummary: RemoteStructureSummary) => {
  return {
    studyId: String(remoteSummary.study),
    sections: remoteSummary.sections.map(remoteSection => ({
      id: String(remoteSection.id),
      name: remoteSection.name,
      subsections: remoteSection.subsections.map(subsection => ({
        id: String(subsection.id),
        name: subsection.name,
        blocks: subsection.blocks.map(remoteBlock => ({
          id: String(remoteBlock.id),
          title: remoteBlock.title,
          variableName: remoteBlock.variable
        }))
      }))
    }))
  }
}

export interface CreateFromStructureResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export const createFromStructure =
  <T>(path: (params: T) => string, dataMapping: DataMapping<T>) =>
  (data: T & { structure: Structure; studyId: string }, responseHandlers?: CreateFromStructureResponseHandlers) => {
    const query = {
      ...mapData(dataMapping, data),
      sections: prepareStructureForSave(data.structure).sections
    }
    const { req, cancel } = fetchApi.post<RemoteStructure>(path(data), query, { studyId: data.studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<CreateFromStructureResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

export interface UpdateStructureResponseHandlers {
  onSuccess?: () => void
  onQuestionUsedInStratification?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const updateStructure =
  <T>(path: (params: T) => string, dataMapping: DataMapping<T>) =>
  (data: T & { structure: Structure; studyId: string }, responseHandlers?: UpdateStructureResponseHandlers) => {
    const query = {
      ...mapData(dataMapping, data),
      sections: prepareStructureForSave(data.structure).sections
    }
    const { req, cancel } = fetchApi.patch(path(data), query, { studyId: data.studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<UpdateStructureResponseHandlers>(
          {
            [BackendError.ECRF_QUESTION_USED_IN_STRATIFICATION]: 'onQuestionUsedInStratification'
          },
          error,
          responseHandlers,
          status
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

export interface FetchStructureResponseHandlers {
  onSuccess?: (structure: Structure) => void
  onRequestError?: () => void
  onNotFound?: () => void
}

export const fetchStructure =
  <T>(path: (params: T) => string) =>
  (params: T & { studyId: string; language?: string }, responseHandlers?: FetchStructureResponseHandlers) => {
    const headers = { studyId: params.studyId, language: params.language }
    const { req, cancel } = fetchApi.get<RemoteStructure>(path(params), {}, headers)

    req.then(({ error, body, status }) => {
      if (error) {
        if (status === 404 && responseHandlers?.onNotFound) {
          responseHandlers.onNotFound()
        } else if (responseHandlers?.onRequestError) {
          responseHandlers.onRequestError()
        }
        // if there is no onNotFound or onRequestError we don't trow any error,
        // because it's normal to not have structure, but BE will return 404
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(parseRemoteStructure(body))
      }
    })

    return cancel
  }

export interface FetchSectionResponseHandlers {
  onSuccess?: (section: Section) => void
  onRequestError?: (code: number) => void
}

export const fetchSection =
  <T>(path: (options: T) => string) =>
  (options: T & { studyId: string }, responseHandlers?: FetchSectionResponseHandlers) => {
    const { req, cancel } = fetchApi.get<RemoteSection>(path(options), {}, { studyId: options.studyId })

    req.then(({ error, body: section, status }) => {
      if (error) {
        createErrorsHandlers<FetchSectionResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(parseRemoteSection(section))
      }
    })

    return cancel
  }

export interface UpdateSectionResponseHandlers {
  onSuccess?: () => void
  onPrefixExistsError?: () => void
  onSuffixExistsError?: () => void
  onVariableUsed?: () => void
  onRequestError?: (code: number) => void
}

export interface UpdateSectionOptions {
  sectionId: string
  name: string
  domain: string
  variablePrefix: string
  variableSuffix: string
  conditionalLogic: ConditionalRule[]
  duplicate?: boolean
}

export const updateSection =
  <T>(path: (options: T) => string) =>
  (options: T & UpdateSectionOptions & { studyId: string }, responseHandlers?: UpdateSectionResponseHandlers) => {
    const requestPath = `${path(options)}${options.duplicate ? '/duplicate' : ''}`
    const requestBody = {
      name: options.name,
      domain: options.domain,
      variable_prefix: options.variablePrefix,
      variable_suffix: options.variableSuffix,
      conditional_logic:
        options.conditionalLogic?.[0] && prepareQuestionConditionalLogicForSave(options.conditionalLogic[0])
    }
    const { req, cancel } = options.duplicate
      ? fetchApi.post<RemoteSection>(requestPath, requestBody, { studyId: options.studyId })
      : fetchApi.patch<RemoteSection>(requestPath, requestBody, { studyId: options.studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<UpdateSectionResponseHandlers>(
          {
            [BackendError.VARIABLE_PREFIX_EXISTS]: 'onPrefixExistsError',
            [BackendError.VARIABLE_SUFFIX_EXISTS]: 'onSuffixExistsError',
            [BackendError.ECRF_QUESTION_VARIABLE_NOT_UNIQUE]: 'onVariableUsed'
          },
          error,
          responseHandlers,
          status
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

export interface FetchSubsectionOptions {
  studyId: string
  sectionId: string
  subsectionId: string
}

export interface FetchSubsectionResponseHandlers {
  onSuccess?: (subsection: Subsection) => void
  onSectionHidden?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const fetchSubsection =
  <T>(path: (options: T) => string) =>
  (options: T & { studyId: string; language?: string }, responseHandlers?: FetchSubsectionResponseHandlers) => {
    const { studyId, language } = options
    const { req, cancel } = fetchApi.get<RemoteSubsection>(path(options), {}, { studyId, language })

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<FetchSubsectionResponseHandlers>(
          { [BackendError.ECRF_SECTION_HIDDEN]: 'onSectionHidden' },
          error,
          responseHandlers,
          status
        )
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(parseRemoteSubsection(body))
      }
    })

    return cancel
  }

export interface UpdateSubsectionResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
}

export interface UpdateSubsectionOptions {
  sectionId: string
  subsectionId: string
  name: string
  domain: string
  conditionalLogic: ConditionalRule[]
}

export const updateSubsection =
  <T>(path: (options: T) => string) =>
  (options: T & UpdateSubsectionOptions & { studyId: string }, responseHandlers?: UpdateSubsectionResponseHandlers) => {
    const { studyId, name, domain, conditionalLogic } = options
    const requestBody = {
      name,
      domain,
      conditional_logic: conditionalLogic[0] && prepareQuestionConditionalLogicForSave(conditionalLogic[0])
    }
    const { req, cancel } = fetchApi.patch<RemoteSection>(path(options), requestBody, { studyId })

    req.then(({ error, status }) => {
      if (error) {
        createErrorsHandlers<UpdateSubsectionResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess()
      }
    })

    return cancel
  }

export interface FetchStructureSummaryResponseHandlers {
  onSuccess?: (summary: StructureSummary) => void
  onRequestError?: (code: number) => void
}

export const fetchStructureSummary =
  <T>(path: (options: T) => string) =>
  (options: T & { studyId: string }, responseHandlers?: FetchStructureSummaryResponseHandlers) => {
    const { req, cancel } = fetchApi.get<RemoteStructureSummary>(path(options), {}, { studyId: options.studyId })

    req.then(({ body, error, status }) => {
      if (error) {
        createErrorsHandlers<FetchStructureSummaryResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(parseRemoteStructureSummary(body))
      }
    })

    return cancel
  }

export interface FoundQuestion {
  title: string
  variable: string
  id: string
  sectionId: string
  subsectionId: string
  repeatedMeasureId?: string
  type: QuestionType
}

interface RemoteFoundQuestion {
  title: string
  variable: string
  id: number
  section_id: number
  subsection_id: number
  repeated_measure_id?: number
  type: QuestionType
}

const parseRemoteFoundQuestion = (question: RemoteFoundQuestion): FoundQuestion => ({
  title: question.title,
  variable: question.variable,
  id: String(question.id),
  sectionId: String(question.section_id),
  subsectionId: String(question.subsection_id),
  repeatedMeasureId: question.repeated_measure_id ? String(question.repeated_measure_id) : null,
  type: question.type
})

export interface SearchQuestionsResponseHandlers {
  onSuccess?: (questions: FoundQuestion[]) => void
  onRequestError?: (code: number) => void
}

export const searchQuestions =
  <T>(path: (params: T) => string) =>
  (
    params: T & {
      search: string
      studyId: string
      withAnswers?: boolean
      withoutAnswers?: boolean
      limit?: number
      types?: QuestionType[]
      excludeRm?: boolean
    },
    responseHandlers?: SearchQuestionsResponseHandlers
  ) => {
    const {
      search: filter_lookup,
      studyId,
      withAnswers: with_answers,
      withoutAnswers: with_no_ecrf_answers,
      limit,
      types,
      excludeRm
    } = params
    const { req, cancel } = fetchApi.get<RemoteFoundQuestion[]>(
      path(params),
      {
        filter_lookup,
        limit: limit || (with_answers || with_no_ecrf_answers ? 20 : 10),
        with_answers,
        with_no_ecrf_answers,
        types,
        exclude_rm: excludeRm
      },
      { studyId }
    )

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<SearchQuestionsResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(body.map(parseRemoteFoundQuestion))
      }
    })

    return cancel
  }

interface FetchDomainsResponseHandlers {
  onSuccess?: (domains: string[]) => void
  onRequestError?: () => void
}

export const fetchDomains = ({ studyId }: { studyId?: string }, responseHandlers?: FetchDomainsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<string[]>('ecrf/domains', {}, { studyId })

  req.then(({ error, body }) => {
    if (error) {
      createErrorsHandlers<FetchDomainsResponseHandlers>({}, error, responseHandlers)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body)
    }
  })

  return cancel
}
