import React from 'react'

import { DatacIcon } from '../../DatacIcon'

const getByteLength = (string: string): number => {
  return new TextEncoder().encode(string[0])[0]
}

const minCharByteValue: number = getByteLength('a')
const maxCharByteValue: number = getByteLength('z')

const minRange: number = minCharByteValue / maxCharByteValue
const maxRange = 1

function mapHue(value: number, start1: number, stop1: number, start2: number, stop2: number) {
  return ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2
}

const colorByInitials = (initials: string, opacity?: number): string => {
  const letterValue = getByteLength((initials[0] || '').toLowerCase())

  if (opacity) {
    return `hsla(${mapHue(letterValue, minRange, maxRange, 0, 360)},50%,50%,${opacity})`
  }
  return `hsl(${mapHue(letterValue, minRange, maxRange, 0, 360)},50%,50%)`
}

interface Props {
  initials: string
  type?: 'circle' | 'square'
}

export const InitialsAvatar = ({ initials, type = 'circle' }: Props) => {
  const hasInitials = initials?.length > 1
  const style =
    type === 'square'
      ? {
          backgroundColor: colorByInitials(initials, 0.1),
          color: colorByInitials(initials)
        }
      : {
          backgroundColor: colorByInitials(initials)
        }
  return (
    <div className="datac-avatar__initials" style={hasInitials ? style : undefined}>
      {initials?.length > 1 ? initials : <DatacIcon name="user" raw />}
    </div>
  )
}
