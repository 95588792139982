import './FilterConfigCalendar.less'

import { DatePicker } from 'antd'
import React from 'react'

import { ConditionalLogicOperator } from '../../../../requests'

interface Props {
  onChange: (value: any) => void
  value: any
  operator: ConditionalLogicOperator
}

export const FilterConfigCalendar: React.FC<Props> = ({ onChange, value, operator }) => {
  return (
    <div className="advanced-filters__dropdown__calendar">
      {operator !== ConditionalLogicOperator.Between && (
        <DatePicker
          className="advanced-filters__dropdown__date-picker__picker"
          getPopupContainer={el => el.closest('.advanced-filters__dropdown__calendar')}
          open={true}
          panelRender={originalPanel => (
            <div className="advanced-filters__dropdown__date-picker__panel">{originalPanel}</div>
          )}
          value={value}
          onCalendarChange={onChange}
        />
      )}
      {operator === ConditionalLogicOperator.Between && (
        <DatePicker.RangePicker
          className="advanced-filters__dropdown__date-picker__picker advanced-filters__dropdown__date-picker__picker--range"
          getPopupContainer={el => el.closest('.advanced-filters__dropdown__calendar')}
          open={true}
          panelRender={originalPanel => (
            <div className="advanced-filters__dropdown__date-picker__panel">{originalPanel}</div>
          )}
          value={value}
          onCalendarChange={onChange}
        />
      )}
    </div>
  )
}
