/* eslint-disable camelcase */
import { ContentState, EditorState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js'
import countries from 'i18n-iso-countries'

import { createErrorsHandlers, prepareSorter } from '../../utils'
import { BackendError } from '../RequestError'
import { SorterOrder } from '../SorterOrder'
import { fetchApi } from '../fetchApi'
import { InternationalPhoneNumber, parseRemotePhoneNumber, phoneNumberToString } from '../subjects'

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/pt.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/it.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/es.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/ja.json'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'))

export const availableCountries = (lang: string) => countries.getNames(lang, { select: 'official' })

export interface RemoteCenterData {
  id: number
  name: string
  country: string
  abbreviation: string
  status: CenterStatus
  token: string
  users_count?: number
  code?: string
  target?: number
  has_subjects?: boolean
  address?: string
  email?: string
  phone?: string
  zip?: string
  city?: string
  lat?: number
  long?: number
  instruction?: string
}

export interface CenterData {
  id: string
  name: string
  country: string
  abbreviation: string
  status: CenterStatus
  token: string
  usersCount?: number
  code?: string
  target?: number
  hasSubjects?: boolean
  address?: string
  email?: string
  phone?: InternationalPhoneNumber
  zip?: string
  city?: string
  lat?: number
  lng?: number
  instruction?: ContentState
}

export interface CentersSorter {
  field: keyof CenterData
  order: SorterOrder
}

export const prepareCentersSorter = (sorter?: CentersSorter) => {
  if (!sorter) return ['name']
  const field = sorter.field

  return [`${sorter.order === SorterOrder.Ascend ? '' : '-'}${field}`, field !== 'name' ? 'name' : null].filter(
    item => !!item
  )
}

const parseRemoteInstruction = (instruction: string) => {
  if (!instruction) return null

  try {
    return convertFromRaw(JSON.parse(instruction))
  } catch {
    const blocksFromHTML = convertFromHTML(instruction)
    return ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  }
}

export const parseRemoteCenterData = (center: RemoteCenterData) => {
  return {
    id: String(center.id),
    name: center.name,
    country: center.country,
    abbreviation: center.abbreviation,
    address: center.address,
    phone: parseRemotePhoneNumber(center.phone),
    email: center.email,
    status: center.status,
    token: center.token,
    usersCount: center.users_count,
    code: center.code,
    target: center.target,
    hasSubjects: center.has_subjects,
    zip: center.zip,
    city: center.city,
    lat: center.lat,
    lng: center.long,
    instruction: parseRemoteInstruction(center.instruction)
  }
}

export enum CenterStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

interface FetchCentersOptions {
  studyId?: string
  assignedToUser?: boolean
  options?: {
    limit?: number
    offset?: number
    sorter?: CentersSorter
    search?: string
    filters?: Record<string, string[]>
  }
}

interface FetchCentersSuccessOptions {
  centers: CenterData[]
  allCentersCount: number
  countries: string[]
}
interface FetchCentersResponseHandlers {
  onSuccess?: (options: FetchCentersSuccessOptions) => void
  onRequestError?: (code: number) => void
}

interface FetchCentersResponse {
  count: number
  countries: string[]
  results: RemoteCenterData[]
}

export const fetchCenters = (
  { studyId, assignedToUser, options }: FetchCentersOptions,
  responseHandlers?: FetchCentersResponseHandlers
) => {
  const sorter = prepareSorter<null, CentersSorter>(null, options.sorter, 'name')
  const query = {
    limit: options.limit,
    offset: options.offset,
    ordering: sorter,
    search: options.search,
    status: options.filters?.status || CenterStatus.Active,
    country: options.filters?.country,
    assigned_to_user: assignedToUser
  }

  const { req, cancel } = fetchApi.get<FetchCentersResponse>('study_centers', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCentersResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess({
        centers: body.results.map(parseRemoteCenterData),
        allCentersCount: body.count,
        countries: body.countries
      })
    }
  })

  return cancel
}

interface ArchiveCenterOptions {
  centerId: string
}

interface ArchiveCenterResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const archiveCenter = ({ centerId }: ArchiveCenterOptions, responseHandlers?: ArchiveCenterResponseHandlers) => {
  const { req, cancel } = fetchApi.patch(`study_centers/${centerId}`, { status: CenterStatus.Archived })
  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<ArchiveCenterResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface AddCenterOptions {
  name: string
  country: string
  abbreviation: string
}

interface AddCenterResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onAbbreviationTaken?: () => void
  onError?: () => void
}

export const addCenter = (
  { name, country, abbreviation }: AddCenterOptions,
  responseHandlers?: AddCenterResponseHandlers
) => {
  const { req, cancel } = fetchApi.post<AddCenterOptions>('study_centers', { name, country, abbreviation })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<AddCenterResponseHandlers>(
        {
          [BackendError.STUDY_CENTER_ABBREVIATION_ALREADY_EXISTS]: 'onAbbreviationTaken'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}
interface FetchCenterOptions {
  centerId: string
}

interface FetchCenterResponseHandlers {
  onSuccess?: (center: CenterData) => void
  onNotFound?: () => void
  onRequestError?: (code: number) => void
}

export const fetchCenter = ({ centerId }: FetchCenterOptions, responseHandlers?: FetchCenterResponseHandlers) => {
  const { req, cancel } = fetchApi.get<RemoteCenterData>(`study_centers/${centerId}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchCenterResponseHandlers>({ 404: 'onNotFound' }, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteCenterData(body))
    }
  })

  return cancel
}

const parseInstructionForSave = (instruction: ContentState | EditorState) => {
  if (!instruction) return instruction
  try {
    return JSON.stringify(convertToRaw((instruction as EditorState)?.getCurrentContent()))
  } catch {
    return JSON.stringify(convertToRaw(instruction as ContentState))
  }
}

export interface SaveCenterOptions {
  centerId?: string
  options: Partial<CenterData>
}

interface SaveCenterResponseHandlers {
  onSuccess?: (data: CenterData) => void
  onRequestError?: (code: number) => void
  onAbbreviationTaken?: () => void
}

export const saveCenter = ({ centerId, options }: SaveCenterOptions, responseHandlers?: SaveCenterResponseHandlers) => {
  const query = {
    name: options.name,
    country: options.country,
    abbreviation: options.abbreviation,
    address: options.address,
    email: options.email,
    phone: phoneNumberToString(options.phone) || null,
    zip: options.zip,
    city: options.city,
    lat: options.lat,
    long: options.lng,
    instruction: parseInstructionForSave(options.instruction)
  }
  const { req, cancel } = centerId
    ? fetchApi.patch<RemoteCenterData>(`study_centers/${centerId}`, query)
    : fetchApi.post<RemoteCenterData>(`study_centers`, query)

  req.then(({ error, status, body }) => {
    if (error) {
      createErrorsHandlers<SaveCenterResponseHandlers>(
        { [BackendError.STUDY_CENTER_ABBREVIATION_ALREADY_EXISTS]: 'onAbbreviationTaken' },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteCenterData(body))
    }
  })

  return cancel
}
