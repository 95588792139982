/* eslint-disable camelcase */
import dayjs from 'dayjs'
import parsePhoneNumber from 'libphonenumber-js/max'

import { StudyAuditTrailsActionType } from '..'
import { createErrorsHandlers } from '../../utils'
import { Feature } from '../Feature'
import { BackendError } from '../RequestError'
import { EconsentStatus } from '../econsent'
import { fetchApi } from '../fetchApi'

interface GenerateSubjectIdResponse {
  subject_id: string
}
interface GenerateSubjectIdResponseHandlers {
  onSuccess?: (subjectId: string) => void
  onRequestError?: (code: number) => void
}

export const generateSubjectId = (
  { studyId }: { studyId: string },
  responseHandlers?: GenerateSubjectIdResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<GenerateSubjectIdResponse>('subjects/generate', {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<GenerateSubjectIdResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.subject_id)
    }
  })

  return cancel
}

export interface InternationalPhoneNumber {
  code: string
  phone: string
  short: string
}

export const phoneNumberToString = (internationalPhoneNumber: InternationalPhoneNumber) => {
  if (!internationalPhoneNumber?.phone || !internationalPhoneNumber?.code) return null
  return `+${internationalPhoneNumber.code} ${internationalPhoneNumber.phone}`
}

export const parseRemotePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return null
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

  return {
    code: parsedPhoneNumber?.countryCallingCode as string,
    phone: parsedPhoneNumber?.nationalNumber as string,
    short: parsedPhoneNumber?.country as string
  }
}

const parseSafeRemoteStudyInclusionId = (studies: RemoteSubjectStudy[]) => {
  if (!studies || !studies.length) {
    return null
  }

  return studies[0].inclusion ? String(studies[0].inclusion) : null
}

interface CreateNewSubjectOptions {
  studyId: string
  subjectId: string
  firstName?: string
  lastName?: string
  email: string
  internationalPhoneNumber?: InternationalPhoneNumber
  studyCenterId: string
  language?: string
}

interface CreateNewSubjectResponseHandlers {
  onSuccess?: (id: string) => void
  onRequestError?: (code: number) => void
  onSubjectIdAlreadyUsed?: () => void
  onEmailAlreadyUsed?: () => void
}

export const createNewSubject = (
  {
    studyId,
    subjectId,
    firstName,
    lastName,
    email,
    internationalPhoneNumber,
    studyCenterId,
    language
  }: CreateNewSubjectOptions,
  responseHandlers?: CreateNewSubjectResponseHandlers
) => {
  const query = {
    datacapt_id: subjectId.toUpperCase(),
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    phone: phoneNumberToString(internationalPhoneNumber) || null,
    study_center_id: studyCenterId,
    language: language || null
  }
  const { req, cancel } = fetchApi.post<SubjectResponse>('subjects', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<CreateNewSubjectResponseHandlers>(
        {
          [BackendError.SUBJECT_ALREADY_EXISTS]: 'onSubjectIdAlreadyUsed',
          [BackendError.SUBJECT_EMAIL_ALREADY_TAKEN]: 'onEmailAlreadyUsed'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.datacapt_id)
    }
  })

  return cancel
}

export interface InviteSubjectToStudyOptions {
  studyId: string
  subjectIds: string[]
  studyCenterId: string
}

interface InviteSubjectToStudyResponseHandlers {
  onSuccess?: (id: string) => void
  onRequestError?: (code: number) => void
  onSubjectIdAlreadyUsed?: () => void
  onNoSubjectWithId?: () => void
}

interface SubjectResponse {
  datacapt_id: string
}

export const inviteSubjectToStudy = (
  { studyId, subjectIds, studyCenterId }: InviteSubjectToStudyOptions,
  responseHandlers?: InviteSubjectToStudyResponseHandlers
) => {
  const query = {
    datacapt_ids: subjectIds.filter(value => value),
    study_center_id: studyCenterId
  }
  const { req, cancel } = fetchApi.post<SubjectResponse>('subjects/invite', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<InviteSubjectToStudyResponseHandlers>(
        {
          [BackendError.SUBJECT_ALREADY_IN_THE_STUDY]: 'onSubjectIdAlreadyUsed',
          [BackendError.SUBJECT_NOT_EXISTS]: 'onNoSubjectWithId'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.datacapt_id)
    }
  })

  return cancel
}

interface RemoteSubjectStudy {
  study: string
  study_center: string
  inclusion: string
}

export enum AccountStatus {
  NoAccount = 'NO_ACCOUNT',
  InvitationSent = 'INVITATION_SENT',
  NotActivated = 'NOT_ACTIVATED',
  Activated = 'ACTIVATED'
}

interface RemoteSubject {
  datacapt_id: string
  first_name: string
  last_name: string
  email: string
  phone: string
  studies: RemoteSubjectStudy[]
  creation_date: string
  inclusion_id: string
  account_status: AccountStatus
  econsent_record_id: string
  econsent_record_status: EconsentStatus
  blocked: boolean
  language: string
  subject_id_field: string
  deleted_in_repository: boolean
}

const parseRemoteSubject = (remoteSubject: RemoteSubject, studyId: string) => ({
  id: remoteSubject.datacapt_id,
  firstName: remoteSubject.first_name,
  lastName: remoteSubject.last_name,
  email: remoteSubject.email,
  internationalPhoneNumber: parseRemotePhoneNumber(remoteSubject.phone),
  creationDate: new Date(remoteSubject.creation_date),
  studyCenterId: remoteSubject.studies?.find(study => study.study === studyId)?.study_center,
  inclusionId: remoteSubject.inclusion_id,
  studyInclusionId: parseSafeRemoteStudyInclusionId(remoteSubject.studies),
  accountStatus: remoteSubject.account_status,
  econsentRecordId: remoteSubject.econsent_record_id,
  econsentRecordStatus: remoteSubject.econsent_record_status,
  blocked: remoteSubject.blocked,
  language: remoteSubject.language,
  datacaptSubjectId: remoteSubject.subject_id_field,
  deletedInRepository: remoteSubject.deleted_in_repository
})

export interface Subject {
  id: string
  firstName: string
  lastName: string
  email: string
  internationalPhoneNumber: InternationalPhoneNumber
  studyCenterId: string
  creationDate: Date
  inclusionId: string
  studyInclusionId: string
  accountStatus: AccountStatus
  econsentRecordId: string
  econsentRecordStatus: EconsentStatus
  blocked: boolean
  language: string
  datacaptSubjectId: string
  deletedInRepository: boolean
}

interface FetchSubjectsInStudyOptions {
  studyId: string
  searchPhrase: string
  limit?: boolean
  hideIncluded?: boolean
  firstAccess?: boolean
  withAnswers?: boolean
  withoutAnswers?: boolean
}

interface FetchSubjectsInStudyResponseHandlers {
  onSuccess?: (subjects: Subject[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectsInStudy = (
  {
    studyId,
    searchPhrase,
    limit = true,
    hideIncluded = true,
    firstAccess,
    withAnswers,
    withoutAnswers
  }: FetchSubjectsInStudyOptions,
  responseHandlers: FetchSubjectsInStudyResponseHandlers
) => {
  const query = {
    filter_lookup: searchPhrase,
    limit,
    hide_included: hideIncluded,
    first_access: firstAccess,
    with_answers: withAnswers,
    with_no_ecrf_answers: withoutAnswers
  }
  const { req, cancel } = fetchApi.get<RemoteSubject[]>('subjects', query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsInStudyResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body?.map(subject => parseRemoteSubject(subject, studyId)) || [])
    }
  })

  return cancel
}

interface FetchSubjectsNotInStudyOptions {
  studyId: string
  searchPhrase: string
  limit?: boolean
}

interface FetchSubjectsNotInStudyResponseHandlers {
  onSuccess?: (subjects: Subject[]) => void
  onRequestError?: (code: number) => void
  onBadFormat?: () => void
}

export const fetchSubjectsNotInStudy = (
  { studyId, searchPhrase: filter_lookup, limit = true }: FetchSubjectsNotInStudyOptions,
  responseHandlers: FetchSubjectsNotInStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteSubject[]>('subjects/invite', { filter_lookup, limit }, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectsNotInStudyResponseHandlers>(
        {
          [BackendError.SUBJECT_BAD_FORMAT]: 'onBadFormat'
        },
        error,
        responseHandlers,
        status
      )
    } else {
      responseHandlers.onSuccess(body?.map(subject => parseRemoteSubject(subject, studyId)))
    }
  })

  return cancel
}

export interface FetchSubjectNotInItemOptions {
  itemId: string
  searchPhrase: string
  feature: Feature
  messageType?: MessageType
  limit?: boolean
  studyId: string
}

export interface FetchSubjectNotInItemResponseHandlers {
  onSuccess?: (subjects: Subject[]) => void
  onRequestError?: (code: number) => void
}

export const fetchSubjectNotInFeature = (
  { itemId, searchPhrase, limit = true, feature, studyId, messageType }: FetchSubjectNotInItemOptions,
  responseHandlers: FetchSubjectNotInItemResponseHandlers
) => {
  const path = [Feature.Epro, Feature.Econsent].includes(feature)
    ? `${feature.toLowerCase()}/invite/${itemId}`
    : `${feature.toLowerCase()}/invite`
  const query = {
    filter_lookup: searchPhrase,
    limit,
    ...(feature !== Feature.Econsult && { message_type: messageType })
  }
  const { req, cancel } = fetchApi.get<RemoteSubject[]>(path, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectNotInItemResponseHandlers>({}, error, responseHandlers, status)
    } else {
      responseHandlers.onSuccess(body?.map(subject => parseRemoteSubject(subject, studyId)))
    }
  })

  return cancel
}

export enum MessageType {
  Email = 'EMAIL',
  Sms = 'SMS',
  NoInvitation = 'NO_INVITATION'
}

export interface InviteSubjectOptions {
  itemId: string
  messageType: MessageType
  messageSubject: string
  messageBody: string
  subjectIds?: string[]
  allSubjects?: boolean
  recordIds?: string[]
  buttonContentId?: string
  basicTargetDate?: string
  feature: Feature
  studyId: string
  scheduleDate?: string
  scheduleTime?: string
  subjectIdFreeText?: string
}

export interface InviteSubjectResponseHandlers {
  onSuccess?: (count?: number) => void
  onTooManyRequests?: () => void
  onNoEmail?: () => void
  onNoPhone?: () => void
  onItemEnded?: () => void
  onPastDate?: () => void
  onEmailTaken?: () => void
  onSubjectNotConsented?: () => void
  onRequestError?: (code: number) => void
  onIdNotUnique?: () => void
}

export const inviteSubjectsToFeature = (
  {
    itemId,
    messageSubject,
    messageBody,
    subjectIds,
    allSubjects,
    recordIds,
    feature,
    studyId,
    messageType,
    scheduleTime,
    scheduleDate,
    buttonContentId,
    subjectIdFreeText
  }: InviteSubjectOptions,
  responseHandlers?: InviteSubjectResponseHandlers
) => {
  const scheduledAt = scheduleTime && scheduleDate ? dayjs(`${scheduleDate} ${scheduleTime}`).utc() : null
  const isResendingInvite = !!recordIds?.length
  const options = isResendingInvite
    ? {
        message_type: messageType,
        message_subject: messageSubject,
        message_body: messageBody,
        ...(feature === Feature.Epro && {
          epro_records: recordIds,
          scheduled_at: scheduledAt,
          button_content_id: buttonContentId
        }),
        ...(feature === Feature.Econsent && {
          econsent_record: recordIds?.length ? recordIds[0] : undefined
        })
      }
    : {
        all_subjects: allSubjects,
        subjects: subjectIds || [],
        message_type: messageType,
        message_subject: messageSubject,
        message_body: messageBody,
        subject_id_free_text: subjectIdFreeText || null,
        ...(feature === Feature.Epro && {
          epro: itemId,
          scheduled_at: scheduledAt,
          button_content_id: buttonContentId
        }),
        ...(feature === Feature.Econsent && {
          econsent_id: itemId
        })
      }

  const inviteTypePath = isResendingInvite ? 'resend' : 'invite'
  const path =
    feature === Feature.Epro ? `invitations/epro/${inviteTypePath}` : `invitations/econsent/${inviteTypePath}`

  const { req, cancel } = fetchApi.post<{ invited_count?: number; sent_count?: number }>(path, options, { studyId })

  req.then(({ body, error, status }) => {
    if (status === 429) {
      if (responseHandlers.onTooManyRequests) {
        responseHandlers.onTooManyRequests()
      }
    } else if (error || status === 429) {
      createErrorsHandlers<InviteSubjectResponseHandlers>(
        {
          429: 'onTooManyRequests',
          [BackendError.USER_WITH_THAT_EMAIL_EXISTS]: 'onEmailTaken',
          [BackendError.INVITE_SUBJECT_HAS_NO_EMAIL]: 'onNoEmail',
          [BackendError.INVITE_SUBJECT_HAS_NO_PHONE]: 'onNoPhone',
          [BackendError.EPRO_STATUS_ENDED]: 'onItemEnded',
          [BackendError.DATETIME_MUST_BE_FUTURE]: 'onPastDate',
          [BackendError.SUBJECT_NOT_CONSENTED]: 'onSubjectNotConsented',
          [BackendError.SUBJECT_ID_NOT_UNIQUE]: 'onIdNotUnique'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body?.invited_count || body?.sent_count)
    }
  })

  return cancel
}

export interface EditSubjectOptions {
  subjectId: string
  studyId: string
  firstName?: string
  lastName?: string
  email: string
  internationalPhoneNumber?: InternationalPhoneNumber
  language?: string
}

interface EditSubjectResponseHandlers {
  onSuccess?: (id: string) => void
  onRequestError?: (code: number) => void
  onEmailAlreadyUsed?: () => void
}

export const editSubject = (
  { studyId, subjectId, firstName, lastName, email, internationalPhoneNumber, language }: EditSubjectOptions,
  responseHandlers?: EditSubjectResponseHandlers
) => {
  const query = {
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    phone: phoneNumberToString(internationalPhoneNumber) || null,
    language: language || null
  }
  const { req, cancel } = fetchApi.patch<SubjectResponse>(`subjects/${subjectId}`, query, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<EditSubjectResponseHandlers>(
        {
          [BackendError.SUBJECT_EMAIL_ALREADY_TAKEN]: 'onEmailAlreadyUsed'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.datacapt_id)
    }
  })

  return cancel
}

export interface DeleteSubjectFromStudyOptions {
  subjectId: string
  studyId: string
}

interface DeleteSubjectFromStudyResponseHandlers {
  onSuccess?: () => void
  onRequestError?: (code: number) => void
  onCantBeDeleted?: () => void
}

export const deleteSubjectFromStudy = (
  { studyId, subjectId }: DeleteSubjectFromStudyOptions,
  responseHandlers?: DeleteSubjectFromStudyResponseHandlers
) => {
  const { req, cancel } = fetchApi.delete(`subjects/${subjectId}`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<DeleteSubjectFromStudyResponseHandlers>(
        {
          [BackendError.SUBJECT_CANT_BE_DELETED]: 'onCantBeDeleted'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

interface RandomiseSubjectOptions {
  studyId: string
  subjectId: string
}

interface RandomiseSubjectResponseHandlers {
  onSuccess?: () => void
  onExcludedSubject?: () => void
  onCantAssignToStratum?: () => void
  onAllocationError?: () => void
  onInvalidAnswers?: () => void
  onRequestError?: (code: number) => void
}

export const randomiseSubject = (
  { studyId, subjectId }: RandomiseSubjectOptions,
  responseHandlers?: RandomiseSubjectResponseHandlers
) => {
  const { req, cancel } = fetchApi.patch(`subjects/${subjectId}/randomise`, {}, { studyId })

  req.then(({ error, status }) => {
    if (error) {
      createErrorsHandlers<RandomiseSubjectResponseHandlers>(
        {
          [BackendError.ECRF_CANNOT_EDIT_EXCLUDED_INCLUSION]: 'onExcludedSubject',
          [BackendError.SUBJECT_CANT_ASSIGN_TO_STRATUM]: 'onCantAssignToStratum',
          [BackendError.STUDY_RANDOMISATION_ALLOCATION_ERROR]: 'onAllocationError',
          [BackendError.STUDY_RANDOMISATION_QUESTION_HAS_INVALID_ANSWER_CONFIGURATION]: 'onInvalidAnswers'
        },
        error,
        responseHandlers,
        status
      )
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess()
    }
  })

  return cancel
}

export interface EconsentTimelineElement {
  date: string
  type: StudyAuditTrailsActionType
  userName: string
}

interface RemoteEconsentTimelineElement {
  timestamp: string
  action_type: StudyAuditTrailsActionType
  user_name: string
}

const parseRemoteEconsentTimelineElement = (timeline: RemoteEconsentTimelineElement) => ({
  date: timeline.timestamp,
  userName: timeline.user_name,
  type: timeline.action_type
})

interface FetchSubjectEconsentTimelineOptions {
  studyId: string
  subjectId: string
  recordId: string
}
interface FetchSubjectEconsentTimelineResponse {
  results: RemoteEconsentTimelineElement[]
}
interface FetchSubjectEconsentTimelineResponseHandlers {
  onSuccess?: (timeline: EconsentTimelineElement[]) => void
  onRequestError?: (code: number) => void
  onError?: () => void
}

export const fetchSubjectEconsentTimeline = (
  { studyId, subjectId, recordId }: FetchSubjectEconsentTimelineOptions,
  responseHandlers?: FetchSubjectEconsentTimelineResponseHandlers
) => {
  const path = `audit_trails/subject/${subjectId}/econsent_timeline/${recordId}`
  const { req, cancel } = fetchApi.get<FetchSubjectEconsentTimelineResponse>(path, {}, { studyId })

  req.then(({ body, error, status }) => {
    if (error) {
      createErrorsHandlers<FetchSubjectEconsentTimelineResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.results.map(parseRemoteEconsentTimelineElement))
    }
  })

  return cancel
}
