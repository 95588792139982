import { Button, Popover } from 'antd'
import Search from 'antd/lib/input/Search'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import ArrowRight from '../../../assets/images/arrow-right.svg?react'
import { useScopedIntl } from '../../../hooks'
import { ConditionalLogicOperator, QuestionType, selectQuestionsTypes } from '../../../requests'
import { DatacIcon, DatacIconName } from '../../DatacIcon'
import { DatacLoading } from '../../DatacLoading'
import { scopedIntlType } from '../../DatacMessage'
import { DatacOption } from '../../DatacSelect'
import {
  AdvancedFilter,
  AdvancedMainFilter,
  fixFilterValue,
  isFilterEmpty,
  noInputOperators
} from '../DatacAdvancedFilters'
import { FilterConfig } from './FilterConfig'

export const operatorToIcon = (operator: ConditionalLogicOperator): DatacIconName => {
  switch (operator) {
    case ConditionalLogicOperator.Equal:
      return 'equal'
    case ConditionalLogicOperator.Between:
      return 'between'
    case ConditionalLogicOperator.Greater:
      return 'gt'
    case ConditionalLogicOperator.GreaterOrEqual:
      return 'gte'
    case ConditionalLogicOperator.Lower:
      return 'lt'
    case ConditionalLogicOperator.LowerOrEqual:
      return 'lte'
    case ConditionalLogicOperator.NotEqual:
      return 'notEqual'
    case ConditionalLogicOperator.Empty:
      return 'empty'
    case ConditionalLogicOperator.NotEmpty:
      return 'notEmpty'
    case ConditionalLogicOperator.StartsWith:
      return 'startsWith'
    case ConditionalLogicOperator.EndsWith:
      return 'endsWith'
    case ConditionalLogicOperator.Contains:
      return 'equal'
    case ConditionalLogicOperator.NotContains:
      return 'notEqual'
    case ConditionalLogicOperator.After:
      return 'lt'
    case ConditionalLogicOperator.Before:
      return 'gt'
    case ConditionalLogicOperator.EqualOrAfter:
      return 'lte'
    case ConditionalLogicOperator.EqualOrBefore:
      return 'gte'
    default:
      return 'helpCircle'
  }
}

const getFilterSelectedValue = (filter: AdvancedFilter, intl: scopedIntlType) => {
  if (noInputOperators.includes(filter?.selection?.operator)) {
    return intl(filter.selection?.operator.toLowerCase())
  }
  if (selectQuestionsTypes.includes(filter.type)) {
    const selection = filter.selection?.value?.find((v: any) => filter.options.map(o => o.value).includes(v))
    return `${filter.options.find(opt => opt.value === selection)?.label || ''}`
  }
  if (filter.type === QuestionType.DateTime) {
    if (Array.isArray(filter.selection.value)) {
      const after = filter.selection.value[0]
      const before = filter.selection.value[1]
      const afterFormat = classNames('DD', {
        MMM: !before || !(before?.year() === after?.year() && before?.month() === after?.month()),
        YYYY: !before || before?.year() !== after?.year()
      })
      return after?.format(afterFormat) || '*'
    }
    return filter.selection.value?.format('DD.MM.YYYY')
  }
  return Array.isArray(filter.selection.value) ? filter.selection.value[0] : filter.selection.value
}

const parseFilterSelectedValue = (filter: AdvancedFilter, intl: scopedIntlType) => {
  const operatorSymbols: Record<ConditionalLogicOperator, React.ReactNode> = {
    [ConditionalLogicOperator.Equal]: ': ',
    [ConditionalLogicOperator.NotEqual]: ` ${intl('is_not')}: `,
    [ConditionalLogicOperator.Greater]: <DatacIcon name={operatorToIcon(ConditionalLogicOperator.Greater)} raw />,
    [ConditionalLogicOperator.GreaterOrEqual]: (
      <DatacIcon name={operatorToIcon(ConditionalLogicOperator.GreaterOrEqual)} raw />
    ),
    [ConditionalLogicOperator.Lower]: <DatacIcon name={operatorToIcon(ConditionalLogicOperator.Lower)} raw />,
    [ConditionalLogicOperator.LowerOrEqual]: (
      <DatacIcon name={operatorToIcon(ConditionalLogicOperator.LowerOrEqual)} raw />
    ),
    [ConditionalLogicOperator.Between]: ': ',
    [ConditionalLogicOperator.Empty]: ': ',
    [ConditionalLogicOperator.NotEmpty]: ': ',
    [ConditionalLogicOperator.StartsWith]: ` ${intl('starts_with')}: `,
    [ConditionalLogicOperator.EndsWith]: ` ${intl('ends_with')}: `,
    [ConditionalLogicOperator.Contains]: ': ',
    [ConditionalLogicOperator.NotContains]: ` ${intl('is_not')}: `,
    [ConditionalLogicOperator.After]: ` ${intl('is_after')}: `,
    [ConditionalLogicOperator.Before]: ` ${intl('is_before')}: `,
    [ConditionalLogicOperator.EqualOrAfter]: ` ${intl('is_on_or_after')}: `,
    [ConditionalLogicOperator.EqualOrBefore]: ` ${intl('is_on_or_before')}: `
  }

  if (isFilterEmpty(filter)) return null
  const operator: ConditionalLogicOperator = filter.selection.operator || ConditionalLogicOperator.Equal
  const andMoreSuffix =
    selectQuestionsTypes.includes(filter.type) && filter.selection?.value?.length > 1
      ? `, +${filter.selection?.value?.length - 1}`
      : ''
  const betweenSuffix =
    operator === ConditionalLogicOperator.Between ? (
      <>
        <ArrowRight />{' '}
        {filter.type === QuestionType.DateTime
          ? filter.selection.value[1]?.format('DD.MM.YYYY')
          : filter.selection.value[1]}
      </>
    ) : null
  const value = getFilterSelectedValue(filter, intl)
  return (
    <span>
      <span data-operator={operator}>{operatorSymbols[operator]}</span>
      <strong>
        <span className="advanced-filters__toggle__value">{value}</span>
        {andMoreSuffix}
        {betweenSuffix}
      </strong>
    </span>
  )
}

interface Props {
  filter: AdvancedFilter
  isFirst: boolean
  isLoading: boolean
  availableFilters: (AdvancedFilter & { available: boolean })[]
  onChange: (filter: AdvancedFilter) => void
  onRemove?: () => void
  canAddAnother?: boolean
  getBlockTypeSymbol: (type: QuestionType) => React.JSX.Element
  extraOperators?: ConditionalLogicOperator[]
}

export const FilterWizard: React.FC<Props> = ({
  filter,
  isFirst,
  isLoading,
  availableFilters,
  onChange,
  onRemove,
  canAddAnother,
  getBlockTypeSymbol,
  extraOperators
}) => {
  const intlFilters = useScopedIntl('advanced_filters')
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<AdvancedFilter>(filter || null)
  const searchRef = useRef(null)

  useEffect(() => {
    if (!isOpen) return
    setTimeout(() => searchRef?.current?.input?.focus(), 200)
  }, [isOpen])

  useEffect(() => {
    setSelectedFilter(filter)
  }, [filter])

  const onOpenChange = (newOpen: boolean) => {
    setIsOpen(newOpen)
    if (!newOpen) {
      if (selectedFilter) {
        // if we edit a selected filter and change it to a different one, we clear the previous one
        if (filter && filter.name !== selectedFilter.name && 'setValue' in filter) {
          ;(filter as AdvancedMainFilter).setValue(undefined)
        }

        if ('setValue' in selectedFilter) {
          ;(selectedFilter as AdvancedMainFilter).setValue((selectedFilter as AdvancedMainFilter).selection)
        }
        onChange(selectedFilter)
        setSelectedFilter(fixFilterValue(selectedFilter))
      } else if (onRemove) {
        onRemove()
      }
      if (!onRemove) {
        setSelectedFilter(null)
      }
    }
  }

  const onOptionsLoaded = (options: DatacOption[]) => {
    if (selectedFilter?.getOptions) {
      setSelectedFilter({
        ...selectedFilter,
        options
      })
    }
  }

  const onAddAnother = () => {
    // a bit ugly, but I guess it's the simplest way to do this
    setTimeout(() => setIsOpen(true), 200)
  }

  const allAvailableFilters = (availableFilters || []).filter(f => f.available || f.name === filter?.name)

  const content = (
    <DatacLoading isLoading={isLoading}>
      <div
        className={classNames('advanced-filters__dropdown', {
          'advanced-filters__dropdown--date': selectedFilter?.type === QuestionType.DateTime,
          'advanced-filters__dropdown--file': selectedFilter?.type === QuestionType.File
        })}
      >
        {!selectedFilter && (
          <div className="advanced-filters__dropdown__filters">
            <div className="advanced-filters__dropdown__filters__search">
              <Search
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={intlFilters('search_placeholder')}
                ref={searchRef}
              />
            </div>
            {!isLoading && (
              <div className="advanced-filters__dropdown__filters__list">
                {allAvailableFilters
                  .filter(
                    filter =>
                      `${filter.label}`.toLowerCase().includes(search.toLowerCase()) ||
                      `${filter.sublabel}`.toLowerCase().includes(search.toLowerCase())
                  )
                  .map(filter => (
                    <button
                      type="button"
                      className={classNames('raw advanced-filters__dropdown__filters__option', {
                        'advanced-filters__dropdown__filters__option--main': filter.main
                      })}
                      key={filter.name}
                      onClick={() => {
                        setSearch('')
                        setSelectedFilter(filter)
                      }}
                    >
                      {filter.icon ? (
                        <div className="block-type-option__symbol">
                          <DatacIcon name={filter.icon} className="block-type-option__icon" type="blue" raw />
                        </div>
                      ) : (
                        getBlockTypeSymbol(filter.type)
                      )}
                      <strong>{filter.label}</strong>
                      {filter.sublabel && <div className="advanced-filters__dropdown__badge">{filter.sublabel}</div>}
                    </button>
                  ))}
              </div>
            )}
          </div>
        )}
        {selectedFilter && (
          <FilterConfig
            filter={selectedFilter}
            onCancel={() => setSelectedFilter(null)}
            onChange={setSelectedFilter}
            onOptionsLoaded={onOptionsLoaded}
            extraOperators={extraOperators}
          />
        )}
      </div>
    </DatacLoading>
  )
  return (
    <Popover
      placement={isFirst ? 'bottomLeft' : 'bottom'}
      content={content}
      trigger={['click']}
      open={isOpen}
      onOpenChange={onOpenChange}
      className={classNames('advanced-filters__dropdown__wrapper', {
        'advanced-filters__dropdown__wrapper--selected': !!selectedFilter,
        'advanced-filters__dropdown__wrapper--add-new': !onRemove
      })}
      overlayClassName="advanced-filters__dropdown__overlay"
    >
      {!selectedFilter && !onRemove && (
        <Button
          type="primary"
          className={classNames('advanced-filters__toggle', { 'advanced-filters__toggle--plus': !isFirst })}
        >
          {isFirst && (
            <>
              <DatacIcon name="filter" raw />
              {intlFilters('add_filter')}
            </>
          )}
          {!isFirst && <DatacIcon name="plus" raw />}
        </Button>
      )}
      {(selectedFilter || onRemove) && (
        <Button
          type="default"
          className={classNames('advanced-filters__toggle advanced-filters__toggle--filled', {
            'advanced-filters__toggle advanced-filters__toggle--invalid': isFilterEmpty(selectedFilter)
          })}
        >
          {selectedFilter && !selectedFilter.main && getBlockTypeSymbol(selectedFilter.type)}
          <span className="advanced-filters__toggle__label">{selectedFilter?.label}</span>
          {isFilterEmpty(selectedFilter) ? null : parseFilterSelectedValue(selectedFilter, intlFilters)}
          <DatacIcon name={isOpen ? 'chevronUp' : 'chevronDown'} />
          <button type="button" className="raw advanced-filters__toggle__remove" onClick={onRemove}>
            <DatacIcon name="x" />
          </button>
        </Button>
      )}
      {!onRemove && selectedFilter && canAddAnother && (
        <Button
          type="primary"
          className="advanced-filters__toggle advanced-filters__toggle--plus"
          onClick={onAddAnother}
        >
          <DatacIcon name="plus" raw />
        </Button>
      )}
    </Popover>
  )
}
