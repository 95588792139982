import './DatacToggleExpand.less'

import classNames from 'classnames'
import React from 'react'

import { DatacIcon, DatacIconType } from '../../components'

interface Props {
  onToggle: (isCollapsed: boolean) => void
  isCollapsed: boolean
  className?: string
  type?: DatacIconType
}

export const DatacToggleExpand: React.FC<Props> = ({ isCollapsed, onToggle, className, type }) => (
  <DatacIcon
    onClick={() => onToggle(!isCollapsed)}
    className={classNames('toggle-expand', className)}
    type={type || 'blue'}
    name={isCollapsed ? 'chevronDown' : 'chevronUp'}
  />
)
