import './DatacSelect.less'

import { Select as AntSelect, RefSelectProps, Spin, Tooltip } from 'antd'
import { SelectProps as AntSelectProps } from 'antd/lib/select'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'

import { DatacIcon, DatacIconType, DatacToggleExpand } from '../../components'

export interface DatacOption {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  prefix?: string | number | React.ReactNode
  suffix?: string | number | React.ReactNode
  label: string | number | React.ReactNode
  sublabel?: string | number | React.ReactNode
  disabled?: boolean
  tooltip?: string
  isRed?: boolean
  className?: string
  options?: DatacOption[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DatacSelectProps extends AntSelectProps<any> {
  options?: DatacOption[]
  renderOption?: (option: DatacOption) => React.ReactNode
  className?: string
  dropDownClassName?: string
  iconColor?: DatacIconType
  onPasteMultiple?: (values: string[]) => void
  selectRef?: React.MutableRefObject<RefSelectProps>
  doNotFilterResults?: boolean
  allowBlank?: boolean
}

export const DatacSelectedOption: React.FC<{
  value: string
  onClose: () => void
  options: DatacOption[]
}> = ({ value, onClose, options }) => {
  const selectedOption = options?.find(option => option.value === value)
  return (
    !!selectedOption && (
      <div className="datac-multi-select__selected-option">
        <span className="datac-multi-select__selected-option-content">
          {selectedOption.label || selectedOption.sublabel}
        </span>
        <span className="datac-multi-select__selected-option-remove">
          <DatacIcon name="x" onClick={onClose} raw className="datac-multi-select__selected-option-icon" />
        </span>
      </div>
    )
  )
}

export const DatacSelect: React.FC<DatacSelectProps> = ({
  options,
  renderOption,
  className,
  dropDownClassName,
  size,
  showSearch = undefined,
  mode = undefined,
  onSearch,
  onSelect,
  onPasteMultiple,
  loading,
  children,
  iconColor,
  autoClearSearchValue,
  selectRef,
  doNotFilterResults,
  onBlur,
  allowBlank,
  ...restProps
}) => {
  const [search, setSearch] = useState('')
  const [currentValues, setCurrentValues] = useState<string[]>([])
  const [isOpened, setIsOpened] = useState(false)
  // @ts-ignore: Unreachable code error
  const inputRef = selectRef || useRef<AntSelect>()

  const onSearchCallback = (value: string) => {
    if (!showSearch || value === search) return

    const valuesArray = value
      ?.split(/[,;]+/)
      ?.map(value => value.trim())
      .filter(value => value)
    if (valuesArray?.length > 1 && onPasteMultiple) {
      onPasteMultiple(valuesArray)
      setCurrentValues([...currentValues, ...valuesArray])
      setSearch('')
      inputRef.current.blur()
      return
    }

    if (onSearch) onSearch(value)
    setSearch(value)
  }

  const onSelectCallback = (value: string) => {
    setCurrentValues([...currentValues, value])
    setSearch('')
    if (onSelect) onSelect(value, null)
    if (onSearchCallback && autoClearSearchValue) onSearchCallback('')
  }

  const onDeselectCallback = (value: string) => {
    setCurrentValues(currentValues.filter(currentValue => currentValue !== value))
  }

  const onVisibleChange = (open: boolean) => {
    setIsOpened(open)
    if (restProps.onDropdownVisibleChange) {
      restProps.onDropdownVisibleChange(open)
    }
  }

  return (
    <AntSelect
      {...restProps}
      className={classNames('datac-select', className)}
      popupClassName={classNames('datac-select__dropdown', dropDownClassName)}
      size={size || 'large'}
      suffixIcon={
        restProps.suffixIcon || <DatacToggleExpand type={iconColor} isCollapsed={!isOpened} onToggle={() => null} />
      }
      onDropdownVisibleChange={onVisibleChange}
      showSearch={showSearch}
      onSearch={showSearch && onSearchCallback}
      filterOption={showSearch ? () => true : null}
      onSelect={onSelectCallback}
      onDeselect={onDeselectCallback}
      onBlur={
        onBlur
          ? (e: React.FocusEvent<HTMLInputElement>) => {
              onBlur(e)
              onSearchCallback && onSearchCallback('')
            }
          : () => onSearchCallback && onSearchCallback('')
      }
      mode={mode}
      tagRender={
        restProps.tagRender ||
        (mode === 'multiple'
          ? props => <DatacSelectedOption value={props.value as string} onClose={props.onClose} options={options} />
          : null)
      }
      dropdownRender={
        loading
          ? () => (
              <div className="datac-select__dropdown-loader">
                <Spin />
              </div>
            )
          : restProps.dropdownRender
      }
      autoClearSearchValue={autoClearSearchValue}
      ref={inputRef}
    >
      {allowBlank && (
        <AntSelect.Option value={null} key={null}>
          {' '}
        </AntSelect.Option>
      )}
      {options
        ? options
            .filter(
              option =>
                doNotFilterResults ||
                (option.label as string)?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
                (option.sublabel as string)?.toLowerCase().indexOf(search.toLowerCase()) >= 0
            )
            .map(option => (
              <AntSelect.Option
                value={option.value}
                key={option.value}
                disabled={option.disabled}
                className={classNames({
                  'datac-select__option--red': option.isRed,
                  [option.className]: !!option.className
                })}
              >
                {renderOption ? (
                  renderOption(option)
                ) : (
                  <div className="datac-select__option">
                    <Tooltip title={option.tooltip}>
                      {option.prefix && <span className="datac-select__option-prefix">{option.prefix}</span>}
                      <span className="datac-select__option-label">{option.label}</span>
                      {option.sublabel && <span className="datac-select__option-sublabel">{option.sublabel}</span>}
                      {option.suffix && <span className="datac-select__option-suffix">{option.suffix}</span>}
                    </Tooltip>
                  </div>
                )}
              </AntSelect.Option>
            ))
        : children}
    </AntSelect>
  )
}

export const DatacSelectOption: typeof AntSelect.Option = AntSelect.Option
