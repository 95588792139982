import { RemoteSectionSignatureBase, SectionSignatureBase, parseRemoteSectionSignatureBase } from './inclusions'

export enum RecordStatus {
  NotStarted = 'NOT_STARTED',
  Queries = 'QUERIES',
  InProgress = 'IN_PROGRESS',
  Verifications = 'VERIFICATION',
  Fulfilled = 'FULFILLED',
  Signed = 'SIGNED',
  Excluded = 'EXCLUDED',
  Completed = 'COMPLETED',
  Sent = 'SENT',
  Uncompleted = 'UNCOMPLETED',
  Created = 'CREATED',
  Archived = 'ARCHIVED',
  Scheduled = 'SCHEDULED',
  Reviewed = 'REVIEWED',
  Unreviewed = 'UNREVIEWED',
  Ended = 'ENDED',
  Unsaved = 'UNSAVED'
}

interface RemoteSubsectionAction {
  id: number
  user_id: number
  user_name: string
  date: string
}

export interface SubsectionAction {
  id: string
  userId: string
  userName: string
  date: Date
}

interface SubsectionSdv {
  questionsCount: string
  answersCount: string
}

interface RemoteSubsectionAdvancement {
  id: number
  status: string
  reviews?: RemoteSubsectionAction[]
  lock?: RemoteSubsectionAction
  questions_count?: number
  filled_answers_with_sdv_count?: number
}
export interface RemoteSectionAdvancement {
  id: number
  name: string
  progress?: number
  status: string
  subsections: RemoteSubsectionAdvancement[]
  signature?: RemoteSectionSignatureBase
  is_signed?: boolean
}

export interface SubsectionAdvancement {
  subsectionId: string
  status: RecordStatus
  reviews?: SubsectionAction[]
  lock?: SubsectionAction
  sdv?: SubsectionSdv
}

export interface SectionAdvancement {
  sectionId: string
  sectionName: string
  progress: number
  status: RecordStatus
  order?: number
  subsections: { [subsectionId: string]: SubsectionAdvancement }
  signature: SectionSignatureBase
  isSigned: boolean
}

export const parseRemoteProgress = (progress: number) => progress || 0

const parseRemoteSubsectionAction = (action: RemoteSubsectionAction) =>
  action?.id && {
    id: String(action.id),
    userId: String(action.user_id),
    userName: action.user_name,
    date: new Date(action.date)
  }

const parseRemoteSubsectionSdv = (remoteSubsection: RemoteSubsectionAdvancement) => ({
  questionsCount: remoteSubsection.questions_count,
  answersCount: remoteSubsection.filled_answers_with_sdv_count
})

export const parseRemoteSectionAdvancement = (remoteAdvancement: RemoteSectionAdvancement, order?: number) => ({
  sectionId: String(remoteAdvancement.id),
  sectionName: remoteAdvancement.name,
  progress: parseRemoteProgress(remoteAdvancement.progress),
  status: remoteAdvancement.status,
  order,
  subsections: remoteAdvancement.subsections.reduce(
    (acc, remoteSubsection) => ({
      ...acc,
      [String(remoteSubsection.id)]: {
        subsectionId: String(remoteSubsection.id),
        status: remoteSubsection.status,
        reviews: remoteSubsection.reviews?.map(parseRemoteSubsectionAction),
        lock: parseRemoteSubsectionAction(remoteSubsection.lock),
        sdv: parseRemoteSubsectionSdv(remoteSubsection)
      }
    }),
    {}
  ),
  signature: parseRemoteSectionSignatureBase(remoteAdvancement.signature),
  isSigned: remoteAdvancement.is_signed
})

export const parseRemoteRecordAdvancement = (sections: RemoteSectionAdvancement[]) =>
  sections
    ? sections.reduce(
        (acc, remoteSectionAdvancement, index) => ({
          ...acc,
          [String(remoteSectionAdvancement.id)]: parseRemoteSectionAdvancement(remoteSectionAdvancement, index)
        }),
        {}
      )
    : {}
