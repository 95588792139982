import './DatacIcon.less'

import { Tooltip as AntTooltip } from 'antd'
import classNames from 'classnames'
import React from 'react'

import * as icons from './icons'

export type DatacIconName = keyof typeof icons

export type DatacIconType =
  | 'transparent'
  | 'blue'
  | 'grey'
  | 'red'
  | 'white'
  | 'green'
  | 'light-green'
  | 'yellow'
  | 'white-on-semi-transparent'
export type DatacIconSize = 'small' | 'normal' | 'big' | 'xbig' | 'xxbig'

export interface DatacIconProps {
  className?: string
  label?: string
  name: DatacIconName
  raw?: boolean
  tooltip?: string
  type?: DatacIconType
  onClick?: () => void
  size?: DatacIconSize
  color?: string
}

export const DatacIcon: React.FC<DatacIconProps> = ({
  name,
  className,
  onClick,
  label,
  type = 'grey',
  raw = false,
  size = 'normal',
  tooltip,
  color
}) => {
  const iconClassName = classNames('datac-icon', className, {
    'datac-icon-box': !raw,
    'datac-icon-box--grey': !raw && type === 'grey',
    'datac-icon-box--blue': !raw && type === 'blue',
    'datac-icon-box--red': !raw && type === 'red',
    'datac-icon-box--green': !raw && type === 'green',
    'datac-icon-box--light-green': !raw && type === 'light-green',
    'datac-icon-box--yellow': !raw && type === 'yellow',
    'datac-icon-box--white': !raw && type === 'white',
    'datac-icon-box--white-on-semi-transparent': !raw && type === 'white-on-semi-transparent',
    'datac-icon-box--small': !raw && size === 'small',
    'datac-icon-box--big': !raw && size === 'big',
    'datac-icon-box--xbig': !raw && size === 'xbig',
    'datac-icon-box--xxbig': !raw && size === 'xxbig',
    'datac-icon-box--clickable': !!onClick
  })
  const Icon = icons[name]
  const style = color ? { color } : {}
  return (
    <AntTooltip title={tooltip}>
      {raw ? (
        <Icon className={iconClassName} onClick={onClick} style={style} />
      ) : (
        <div className={iconClassName} onClick={onClick} data-cy={name}>
          {label} <Icon style={style} />
        </div>
      )}
    </AntTooltip>
  )
}
