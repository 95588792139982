/* eslint-disable camelcase */
import { createErrorsHandlers } from '../../../../utils'
import { fetchApi } from '../../../fetchApi'

export enum ConditionalLogicJoinOperator {
  And = 'AND',
  Or = 'OR'
}

export enum ConditionalLogicOperator {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  Lower = 'LOWER',
  Greater = 'GREATER',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  Between = 'BETWEEN',
  Empty = 'EMPTY',
  NotEmpty = 'NOT_EMPTY',
  StartsWith = 'STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  Contains = 'CONTAINS',
  NotContains = 'NOT_CONTAINS',
  Before = 'BEFORE',
  After = 'AFTER',
  EqualOrBefore = 'EQUAL_OR_BEFORE',
  EqualOrAfter = 'EQUAL_OR_AFTER'
}

export enum ConditionalLogicAction {
  Show = 'SHOW',
  Prefill = 'PREFILL'
}

export interface ReferenceAnswer {
  [questionId: string]: number | string[]
}

export interface Condition {
  referenceQuestionId: string
  answerValue: number | string[]
  operator: ConditionalLogicOperator
  joinOperator?: ConditionalLogicJoinOperator
  action?: ConditionalLogicAction
  prefilledValue?: string
}

export interface ConditionalRule {
  sourceQuestionId?: string
  sourceStaticContentId?: string
  sourceSubsectionId?: string
  sourceSectionId?: string
  conditions: Condition[]
  blockIds?: string[]
}

export interface RemoteCondition {
  reference_question: number
  answer_value:
    | number
    | {
        selections: string[]
      }
  operator: ConditionalLogicOperator
}

export interface RemoteConditionalRule {
  join_operator: ConditionalLogicJoinOperator
  prefilled_value: string
  action: ConditionalLogicAction
  source_question?: number
  source_static_content?: number
  source_subsection?: number
  source_section?: number
  conditions: RemoteCondition[]
  block_ids?: string[]
}

export const parseRemoteCondition = (
  remoteCondition: RemoteCondition,
  joinOperator: ConditionalLogicJoinOperator,
  action?: ConditionalLogicAction,
  prefilledValue?: string
) => ({
  referenceQuestionId: remoteCondition.reference_question ? String(remoteCondition.reference_question) : null,
  operator: remoteCondition.operator,
  answerValue:
    typeof remoteCondition.answer_value === 'object'
      ? remoteCondition.answer_value.selections
      : remoteCondition.answer_value,
  action,
  prefilledValue,
  joinOperator
})

export const parseRemoteSingleConditionalLogic = (remoteConditionalLogic: RemoteConditionalRule) => {
  if (!remoteConditionalLogic) return null
  return {
    sourceQuestionId: remoteConditionalLogic.source_question
      ? String(remoteConditionalLogic.source_question)
      : undefined,
    sourceStaticContentId: remoteConditionalLogic.source_static_content
      ? String(remoteConditionalLogic.source_static_content)
      : undefined,
    sourceSubsectionId: remoteConditionalLogic.source_subsection
      ? String(remoteConditionalLogic.source_subsection)
      : undefined,
    sourceSectionId: remoteConditionalLogic.source_section ? String(remoteConditionalLogic.source_section) : undefined,
    conditions: remoteConditionalLogic.conditions?.length
      ? remoteConditionalLogic.conditions.map(remoteCondition =>
          parseRemoteCondition(
            remoteCondition,
            remoteConditionalLogic.join_operator,
            remoteConditionalLogic.action,
            remoteConditionalLogic.prefilled_value
          )
        )
      : [],
    blockIds: remoteConditionalLogic.block_ids?.map(String) || []
  }
}

export const parseRemoteConditionalLogic = (remoteConditionalLogic: RemoteConditionalRule) => {
  if (!remoteConditionalLogic) return null
  return [parseRemoteSingleConditionalLogic(remoteConditionalLogic)]
}

export const prepareConditionForSave = (condition: Condition) => {
  let answer: any = condition.answerValue
  if (
    condition?.answerValue !== null &&
    typeof condition.answerValue === 'object' &&
    !(
      [ConditionalLogicOperator.Empty, ConditionalLogicOperator.NotEmpty].includes(condition.operator) &&
      answer === null
    )
  ) {
    if ('format' in condition.answerValue && typeof condition.answerValue.format === 'function') {
      answer = condition.answerValue.format('YYYY-MM-DD')
    } else {
      answer = {
        selections: condition.answerValue.map((v: any) => {
          if (typeof v === 'object' && 'format' in v && typeof v.format === 'function') {
            return v.format('YYYY-MM-DD')
          } else return v
        })
      }
    }
  }
  return {
    reference_question: condition.referenceQuestionId,
    answer_value: answer,
    operator: condition.operator
  }
}

export const prepareQuestionConditionalLogicForSave = (conditionalLogic: ConditionalRule) => {
  if (!conditionalLogic?.conditions?.length) return null
  const finalCondition = conditionalLogic?.conditions[conditionalLogic.conditions.length - 1]
  return {
    join_operator: finalCondition.joinOperator,
    prefilled_value: finalCondition.action === ConditionalLogicAction.Prefill ? finalCondition.prefilledValue : null,
    action: finalCondition.action,
    conditions: conditionalLogic.conditions.map(prepareConditionForSave)
  }
}

export interface FetchConditionalLogicResponseHandlers {
  onSuccess?: (conditionalRule: ConditionalRule[]) => void
  onRequestError?: (code: number) => void
}

interface FetchConditionalLogicResponse {
  results: RemoteConditionalRule[]
}

export const fetchConditionalLogic =
  <T>(path: (params: T) => string) =>
  (params: T, responseHandlers?: FetchConditionalLogicResponseHandlers) => {
    const { req, cancel } = fetchApi.get<FetchConditionalLogicResponse>(path(params), {})

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<FetchConditionalLogicResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(body.results.map(parseRemoteSingleConditionalLogic))
      }
    })

    return cancel
  }
