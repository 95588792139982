import './DatacLoading.less'

import { Spin } from 'antd'
import classNames from 'classnames'
import React from 'react'

interface Props {
  isLoading: boolean
  transparent?: boolean
  size?: 'small' | 'default' | 'large'
  children?: React.ReactNode
}

export const DatacLoading: React.FC<Props> = ({ isLoading, children, transparent = false, size = 'large' }) => {
  return isLoading ? (
    <div className={classNames('datac-loading', transparent && 'datac-loading--transparent')}>
      <Spin size={size} />
      {transparent && children}
    </div>
  ) : (
    <> {children} </>
  )
}
