import './DatacPhoneInput.less'

import { ConfigProvider, CountryPhoneInput, CountryPhoneInputProps } from 'antd-country-phone-input'
import React, { Suspense, useEffect } from 'react'

import { localizedCountries } from '../../requests'
import { localeFromPath } from '../../utils'
import { DatacLoading } from '../DatacLoading'

const Flag = React.lazy(() => import('react-world-flags'))

export const DatacPhoneInput: React.FC<CountryPhoneInputProps> = props => {
  const className = `${props.className || ''} datac-form-item datac-form-item--country-phone`

  const getDropdown = () => {
    const flag = document.querySelector('.ant-select-dropdown .rc-virtual-list .datac-form-item--country-phone__flag')
    return flag?.closest('.ant-select-dropdown') as HTMLElement
  }

  const openDropdown = (event: Event) => {
    event.stopPropagation()
    event.preventDefault()
    const dropdown = getDropdown()
    setTimeout(() => {
      if (!dropdown) return
      dropdown.style.display = 'block'
      dropdown.style.pointerEvents = 'auto'
    }, 100)
  }

  const closeDropdown = () => {
    const dropdown = getDropdown()
    if (dropdown) dropdown.style.display = 'none'
  }

  useEffect(() => {
    // CountryPhoneInput doesn't work properly with antd5
    // we need this quite wild hack :(

    window.addEventListener('click', closeDropdown)

    const codeInput = document.querySelector('.antd-country-phone-input .ant-select')
    codeInput.addEventListener('click', openDropdown)

    return () => {
      window.removeEventListener('click', closeDropdown)
      codeInput.removeEventListener('click', openDropdown)
    }
  }, [])

  return (
    <ConfigProvider
      locale={localizedCountries(localeFromPath())}
      // @ts-expect-error: phoneCode must be a number, but we put it in emoji and pass '' for it
      areaMapper={area => {
        return {
          ...area,
          emoji: (
            <>
              <Suspense fallback={<DatacLoading size="small" isLoading />}>
                <Flag code={area.short} className="datac-form-item--country-phone__flag" data-code={area.phoneCode} />
              </Suspense>
              <span className="datac-form-item--country-phone__code">{area.phoneCode}</span>
            </>
          ),
          phoneCode: ''
        }
      }}
    >
      <CountryPhoneInput {...props} className={className} />
    </ConfigProvider>
  )
}
