import React, { Suspense, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { Route, Routes, useNavigate } from 'react-router'

import { DatacLoading } from 'common/components'
import { getAppPath, getIntlMessages, localeFromPath } from 'common/utils'

const NotFound = React.lazy(() => import('./404'))
const Auth = React.lazy(() => import('./auth'))
const BookAppointments = React.lazy(() => import('./book-appointments'))
const Bookings = React.lazy(() => import('./bookings'))
const Calendar = React.lazy(() => import('./calendar'))
const Econsult = React.lazy(() => import('./econsult'))
const EconsultEnded = React.lazy(() => import('./econsult/ended'))
const EproPublic = React.lazy(() => import('./epro-public'))
const EproSurvey = React.lazy(() => import('./epro-survey'))
const PaymentOrders = React.lazy(() => import('./payment-orders'))
const Products = React.lazy(() => import('./products'))
const Public = React.lazy(() => import('./public'))
const Recruitment = React.lazy(() => import('./recruitment'))
const RecruitmentSurvey = React.lazy(() => import('./recruitment-survey'))
const S = React.lazy(() => import('./s'))
const SecureEconsent = React.lazy(() => import('./secure-econsent'))
const Settings = React.lazy(() => import('./settings'))
const SideBySide = React.lazy(() => import('./side-by-side'))
const Studies = React.lazy(() => import('./studies'))
const SubjectDatabase = React.lazy(() => import('./subject-database'))
const SubjectSurvey = React.lazy(() => import('./subject-survey'))

const IndexPage: React.FC = () => {
  const locale = localeFromPath()
  const [messages, setMessages] = useState<Record<string, string> | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!locale) {
      navigate(`/en${getAppPath() === '/' ? '/studies' : getAppPath()}`)
      return
    }
    const loadMessages = async () => {
      const loadedMessages = await getIntlMessages(locale)
      setMessages(loadedMessages)
    }

    loadMessages()
  }, [locale])

  return (
    messages && (
      <IntlProvider locale={locale} messages={messages}>
        <Suspense fallback={<DatacLoading isLoading />}>
          <Routes>
            <Route path={`/${locale}/*`} element={<MainRoute />} />
          </Routes>
        </Suspense>
      </IntlProvider>
    )
  )
}

const MainRoute: React.FC = () => {
  return (
    <Suspense fallback={<DatacLoading isLoading />}>
      <Routes>
        <Route element={<Auth />} path="/auth/*" />
        <Route element={<BookAppointments />} path="/book-appointments" />
        <Route element={<Bookings />} path="/bookings" />
        <Route element={<Calendar />} path="/calendar/*" />
        <Route element={<Econsult />} path="/econsult" />
        <Route element={<EconsultEnded />} path="/econsult/ended" />
        <Route element={<EproPublic />} path="/epro-public" />
        <Route element={<EproSurvey />} path="/epro-survey" />
        <Route element={<PaymentOrders />} path="/payment-orders" />
        <Route element={<Products />} path="/products" />
        <Route element={<Public />} path="/public/*" />
        <Route element={<Recruitment />} path="/recruitment/*" />
        <Route element={<RecruitmentSurvey />} path="/recruitment-survey" />
        <Route element={<S />} path="/s" />
        <Route element={<SecureEconsent />} path="/secure-econsent" />
        <Route element={<SubjectDatabase />} path="/subject-database/*" />
        <Route element={<SubjectSurvey />} path="/subject-survey" />
        <Route element={<SideBySide />} path="/side-by-side/*" />
        <Route element={<Settings />} path="/settings/*" />
        <Route element={<Studies />} path="/studies/*" />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </Suspense>
  )
}

export default IndexPage
