import mime from 'mime'

import { createErrorsHandlers, proxyMinioToLocalhost } from '../utils'
import { fetchApi } from './fetchApi'

export interface RcFile extends File {
  uid: string
}

export interface RcCustomRequestOptions {
  file: RcFile
}

export const defaultSupportedFileExtensions = [
  'pdf',
  'jpeg',
  'jpg',
  'png',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'txt',
  'csv',
  'mov',
  'mp4',
  'avi',
  'wmv',
  'mkv',
  'dcm',
  'wav',
  'aiff',
  'alac',
  'flac',
  'mp3',
  'aac',
  'wma',
  'ogg',
  'zip'
]

export const supportedImageExtensions = ['jpeg', 'jpg', 'png']

export const defaultMaxFileBytes = 10485760

export const downloadFile = (url: string, filename = 'FileName') => {
  const userAgent = window.navigator.userAgent
  const isIos = /iPad/.test(userAgent) || /iPhone/.test(userAgent)
  const isWebkit = /WebKit/.test(userAgent)
  const isSafariIos = isIos && isWebkit && !/CriOS/.test(userAgent)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = proxyMinioToLocalhost(url)
  a.rel = 'nofollow'
  // safari on ios does not support download attribute and won't open new tab by script
  if (!isSafariIos) {
    a.target = '_blank'
    a.download = filename
  }
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export enum FileState {
  Uploading = 'UPLOADING',
  Deleting = 'DELETING',
  Preloading = 'PRELOADING',
  Error = 'ERROR',
  Ready = 'READY'
}

export interface BuilderImageConfig {
  fileId: string
  fileUrl?: string
  fileState?: FileState
}

export interface FileInfo {
  id: string
  uuid?: string
  uid?: string // we need also this as it's used in antd upload
  name?: string
  size?: number
  state?: FileState
  url?: string
  uploadedBy?: string
  dateAdded?: Date
  uploadedSize?: number
  parentId?: string
}

interface fetchFileUrlsOptions {
  fileIds: string[]
}

type FilesUrls = Record<string, string>

interface fetchFileUrlsResponseHandlers {
  onSuccess?: (filesUrls: FilesUrls) => void
  onRequestError?: (code: number) => void
}

export const fetchFileUrls = ({ fileIds }: fetchFileUrlsOptions, responseHandlers?: fetchFileUrlsResponseHandlers) => {
  const { req, cancel } = fetchApi.get<FilesUrls>(`uploaded_files/${fileIds.join(',')}`)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<fetchFileUrlsResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body || {})
    }
  })

  return cancel
}

export interface FetchOpenEndpointFilesUrls {
  fileIds: string[]
}

export interface FetchOpenEndpointFilesUrlsResponseHandlers {
  onSuccess?: (files: FilesUrls) => void
  onRequestError?: (code: number) => void
}

export const fetchOpenEndpointFilesUrls =
  (path: string) =>
  (token?: string, qrCode?: string) =>
  ({ fileIds }: FetchOpenEndpointFilesUrls, responseHandlers?: FetchOpenEndpointFilesUrlsResponseHandlers) => {
    const url = `${path}/${token ? `${token}/` : ''}${fileIds.join(',')}`
    const { req, cancel } = fetchApi.get<FilesUrls>(url, {}, { qrCode })

    req.then(({ error, body, status }) => {
      if (error) {
        createErrorsHandlers<FetchOpenEndpointFilesUrlsResponseHandlers>({}, error, responseHandlers, status)
      } else if (responseHandlers?.onSuccess) {
        responseHandlers.onSuccess(body)
      }
    })

    return cancel
  }

export interface RemoteFile {
  uuid: string
  name: string
  size: number
}

export const parseRemoteFile = (file: RemoteFile) => ({
  id: String(file.uuid),
  name: file.name,
  size: file.size
})

export enum ImageUploadType {
  // upload url postfixes as values
  BuilderImage = 'builder-image',
  Logo = 'logos',
  Recruitment = 'recruitment',
  Avatar = 'avatar',
  RichText = 'rich-text'
}

interface SaveImageOptions {
  file: File
  uploadType: ImageUploadType
  studyId?: string
  datacaptId?: string
}

interface SimpleFile {
  uuid: string
  file: string
}

type SaveImageResponse = { files_data: RemoteFile[] } | SimpleFile

interface SaveImageResponseHandlers {
  onSuccess?: (savedFile: FileInfo | SimpleFile) => void
  onRequestError?: (code?: number) => void
}

export const saveImage = (
  { file, uploadType, studyId, datacaptId }: SaveImageOptions,
  responseHandlers?: SaveImageResponseHandlers
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query: any = {
    url: `uploaded_files/${uploadType}`,
    body: { file },
    headerProps: { studyId }
  }
  switch (uploadType) {
    case ImageUploadType.Recruitment:
      query.url = 'uploaded_files/logos' // recruitment images are saved as logos without studyId
      query.headerProps = undefined
      break
    case ImageUploadType.Avatar:
      query.url = 'uploaded_files/subject_photos'
      query.body.subject = datacaptId
      query.headerProps = undefined
      break
    case ImageUploadType.RichText:
      query.headerProps = undefined
      break
  }
  const { req, cancel } = fetchApi.postFile<SaveImageResponse>(query.url, query.body, query.headerProps)

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<SaveImageResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      if ('files_data' in body) responseHandlers.onSuccess(parseRemoteFile(body.files_data[0]))
      else responseHandlers.onSuccess(body)
    }
  })

  return cancel
}

interface GetUploadUrlResponseHandlers {
  onSuccess?: (uploadUrl: string, fileId: string) => void
  onRequestError?: (code: number) => void
}

interface RemoteUploadUrl {
  upload_url: string
  uuid: string
}

interface GetUploadUrlOptions {
  name: string
  size: number
  token?: string
}

export const getUploadUrl = (
  { name, size, token }: GetUploadUrlOptions,
  responseHandlers: GetUploadUrlResponseHandlers
) => {
  const { req, cancel } = fetchApi.get<RemoteUploadUrl>('uploaded_files/upload-stream-url', { name, size, token })

  req.then(({ error, body }) => {
    if (error) {
      if (responseHandlers?.onRequestError) {
        responseHandlers.onRequestError(error?.code)
      } else {
        throw error
      }
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(body.upload_url, body.uuid)
    }
  })
  return cancel
}

interface UploadFileToS3ResponseHandlers {
  onProgressUpdate?: ({ size, uploadedSize }: Partial<FileInfo>) => void
  onUploaded?: () => void
  onRequestError?: (code?: number) => void
}

interface UploadFileToS3Options {
  uploadUrl: string
  file: File
}

export const uploadFileToS3 = (
  { uploadUrl, file }: UploadFileToS3Options,
  responseHandlers: UploadFileToS3ResponseHandlers
) => {
  const xhr = new XMLHttpRequest()

  xhr.open('PUT', proxyMinioToLocalhost(uploadUrl, true), true)
  xhr.setRequestHeader('Content-Type', mime.getType(file.name) || 'application/octet-stream')

  xhr.upload.onprogress = event => {
    if (event.lengthComputable && responseHandlers.onProgressUpdate) {
      responseHandlers.onProgressUpdate({ size: event.total, uploadedSize: event.loaded })
    }
  }
  xhr.onerror = () => {
    if (responseHandlers.onRequestError) {
      responseHandlers.onRequestError(xhr.status)
    }
  }
  xhr.onloadend = () => {
    if (xhr.status === 200 && responseHandlers.onUploaded) {
      responseHandlers.onUploaded()
    }
  }

  xhr.send(file)

  return () => xhr.abort()
}
