import { createErrorsHandlers } from '../../../../utils'
import { fetchApi } from '../../../fetchApi'
import { RecordStatus } from '../../../sectionAdvancement'

export enum ButtonContentType {
  Randomisation = 'RANDOMISATION',
  Epro = 'EPRO'
}

export interface ButtonContent {
  id?: string
  tempId?: string
  type: ButtonContentType
  name: string
}

export interface RemoteButtonContent {
  id: string
  type: string
  name: string
}

const parseRemoteButtonContentType = (remoteButtonType: string) => {
  switch (remoteButtonType) {
    case 'RANDOMISATION':
      return ButtonContentType.Randomisation
    case 'EPRO':
      return ButtonContentType.Epro
    default:
      return null
  }
}

export const parseRemoteButtonContent = (remoteButtonContent: RemoteButtonContent) => {
  return {
    id: String(remoteButtonContent.id),
    type: parseRemoteButtonContentType(remoteButtonContent.type),
    name: remoteButtonContent.name
  }
}

const prepareButtonContentTypeForSave = (type: ButtonContentType) => {
  switch (type) {
    case ButtonContentType.Randomisation:
      return 'RANDOMISATION'
    case ButtonContentType.Epro:
      return 'EPRO'
    default:
      return null
  }
}

export const prepareButtonContentForSave = (buttonContent: ButtonContent, order: number, subsectionId: string) => {
  return {
    id: buttonContent.id,
    type: prepareButtonContentTypeForSave(buttonContent.type),
    name: buttonContent.name,
    subsection: subsectionId,
    order
  }
}
interface RemoteEproButtonOptions {
  epro_name: string
  status: RecordStatus
  id: string
  epro_id: string
  start_date: string
  questions_count: number
  invitation_url: string
}

export interface EproButtonOptions {
  eproName: string
  eproRecordStatus: RecordStatus
  eproRecordId: string
  eproId: string
  eproRecordStartDate: string
  eproQuestionsCount: number
  eproRecordInvitationUrl?: string
}

const parseRemoteEproButtonOptions = (options: RemoteEproButtonOptions): EproButtonOptions => {
  return {
    eproName: options?.epro_name,
    eproRecordStatus: options?.status && options.status,
    eproRecordId: options?.id,
    eproId: options?.epro_id,
    eproQuestionsCount: options?.questions_count,
    eproRecordStartDate: options?.start_date,
    eproRecordInvitationUrl: options?.invitation_url
  }
}

interface FetchEproButtonContentOptions {
  subjectId: string
  buttonId: string
  studyId: string
}

interface FetchEproButtonResponse {
  last_epro_record: RemoteEproButtonOptions
}

interface FetchEproButtonContentResponseHandlers {
  onSuccess?: (options: EproButtonOptions) => void
  onRequestError?: (code: number) => void
}

export const fetchEproButtonOptions = (
  { subjectId, buttonId, studyId }: FetchEproButtonContentOptions,
  responseHandlers?: FetchEproButtonContentResponseHandlers
) => {
  const path = `answers/button-content/${buttonId}/${subjectId}`
  const { req, cancel } = fetchApi.get<FetchEproButtonResponse>(path, {}, { studyId })

  req.then(({ error, body, status }) => {
    if (error) {
      createErrorsHandlers<FetchEproButtonContentResponseHandlers>({}, error, responseHandlers, status)
    } else if (responseHandlers?.onSuccess) {
      responseHandlers.onSuccess(parseRemoteEproButtonOptions(body.last_epro_record))
    }
  })

  return cancel
}
