import { Condition, SorterOrder } from '../requests'
import { AvailableLocales } from '../requests/generalSettings/users'
import { changeLocale, localeFromPath } from './misc'

export enum LocalStorageKey {
  PageToGoBack = 'datac_page_to_go_back',
  SurveyPreservedData = 'datac_page_survey_preserved_data',
  IsWelcomeBannerDisabled = 'datac_page_is_welcome_banner_disabled',
  LastRequestTime = 'datac_page_last_request_time',
  LastLoginType = 'datac_page_last_login_type',
  LastLocationPath = 'datac_last_url',
  Language = 'datac_language',
  SubjectDatabaseLanguage = 'datac_db_language',
  LastFeature = 'datac_last_feature',
  LastStudy = 'datac_last_study',
  LastTab = 'datac_last_tab',
  AutoReloadingTime = 'datac_auto_reloading_time'
}

export const getLocalStorageItem = <T extends string>(key: LocalStorageKey, defaultValue: T) => {
  const savedItem = localStorage.getItem(key)
  return (savedItem as T) || defaultValue
}

export enum PagesAvailableToGoBack {
  INCLUSIONS = 'inclusions#back',
  MONITORING = 'monitoring#back'
}

export enum TabsAvailableToGoBack {
  QUERIES = 'queries',
  REVIEWS = 'reviews',
  SDV = 'sdv',
  MISSING = 'missing'
}
export type PageToGoBack<T> = {
  studyPage?: PagesAvailableToGoBack
  studyTab?: TabsAvailableToGoBack
  pageNumber?: number
  pageSize?: number
  search?: string
  filters?: Record<string, string[]>
  conditions?: Condition[]
  sorter?: {
    field: keyof T
    order: SorterOrder
  }
}

export const getPageToGoBack = <T>() =>
  (JSON.parse(localStorage.getItem(LocalStorageKey.PageToGoBack)) as PageToGoBack<T>)?.studyPage ||
  PagesAvailableToGoBack.INCLUSIONS

export const getPagePropsAfterGoingBack = <T>() => {
  // when going back, we are adding #back hash (see PagesAvailableToGoBack) to know if it's back action
  // after checking it, we are removing this hash
  if (window.location.hash !== '#back')
    return { pageNumber: 0, pageSize: 25, search: '', filters: null, conditions: null, sorter: null, studyTab: null }
  window.history.replaceState(null, null, ' ')

  const savedPageProps = JSON.parse(localStorage.getItem(LocalStorageKey.PageToGoBack)) as PageToGoBack<T>
  return {
    pageNumber: savedPageProps?.pageNumber || 0,
    pageSize: savedPageProps?.pageSize || 0,
    studyTab: savedPageProps?.studyTab || null,
    search: savedPageProps?.search || '',
    filters: savedPageProps?.filters || null,
    conditions: savedPageProps?.conditions || null,
    sorter: savedPageProps?.sorter || null
  }
}
export const setPageToGoBack = <T>(pageToGoBack: PageToGoBack<T>) =>
  localStorage.setItem(LocalStorageKey.PageToGoBack, JSON.stringify(pageToGoBack))

export enum LanguageScope {
  GLOBAL = 'global',
  SUBJECTDB = 'subject-database'
}

export const setLanguage = (lang: string, scope: LanguageScope = LanguageScope.GLOBAL) =>
  localStorage.setItem(
    scope === LanguageScope.SUBJECTDB ? LocalStorageKey.SubjectDatabaseLanguage : LocalStorageKey.Language,
    lang
  )

export const getLanguage = (scope: LanguageScope = LanguageScope.GLOBAL) => {
  if (scope === LanguageScope.SUBJECTDB) {
    const scopedLang = localStorage.getItem(LocalStorageKey.SubjectDatabaseLanguage)
    if (scopedLang) return scopedLang
  }
  return localStorage.getItem(LocalStorageKey.Language) || localeFromPath()
}

export const redirectToLastLanguage = () => changeLocale(getLanguage() as AvailableLocales)
