import { Button, Checkbox, Input, InputNumber, Popover } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { ConditionalLogicOperator, QuestionType, numberQuestionsTypes, selectQuestionsTypes } from '../../../requests'
import { DatacIcon } from '../../DatacIcon'
import { DatacLoading } from '../../DatacLoading'
import { DatacOption } from '../../DatacSelect'
import { AdvancedFilter, noInputOperators } from '../DatacAdvancedFilters'
import { FilterConfigCalendar } from './FilterConfigCalendar/FilterConfigCalendar'
import { operatorToIcon } from './FilterWizard'

const getDefaultOperator = (type: QuestionType) => {
  switch (type) {
    case QuestionType.Text:
      return ConditionalLogicOperator.Contains
    case QuestionType.File:
      return ConditionalLogicOperator.NotEmpty
    default:
      return ConditionalLogicOperator.Equal
  }
}

interface OperatorDropdownProps {
  options: ConditionalLogicOperator[]
  current: ConditionalLogicOperator
  onChange: (operator: ConditionalLogicOperator) => void
}

const OperatorDropdown: React.FC<OperatorDropdownProps> = ({ options, current, onChange }) => {
  const intlConditions = useScopedIntl('studies.builder.question_builder.conditions')
  const [isOpen, setIsOpen] = useState(false)

  const onSelect = (option: ConditionalLogicOperator) => {
    onChange(option)
    setIsOpen(false)
  }

  return (
    <div className="advanced-filters__operator-dropdown__toggle" data-current={current}>
      <Popover
        placement="bottomLeft"
        open={isOpen}
        onOpenChange={setIsOpen}
        trigger={['click']}
        content={
          <ul className="advanced-filters__operator-dropdown">
            {options.map(option => (
              <li key={option}>
                <button
                  type="button"
                  onClick={() => onSelect(option)}
                  className={classNames('raw', { selected: current === option })}
                >
                  <DatacIcon name={operatorToIcon(option)} type="blue" size="small" />
                  <span>{intlConditions(`operator.${option.toLowerCase()}`)}</span>
                </button>
              </li>
            ))}
          </ul>
        }
        overlayClassName="advanced-filters__operator-dropdown__overlay"
      >
        <Button type="primary" className="advanced-filters__operator-dropdown__toggle__button">
          <DatacIcon name={operatorToIcon(current)} raw />
        </Button>
      </Popover>
      {current && (
        <span className="advanced-filters__operator-dropdown__toggle__value">
          {intlConditions(`operator.${current.toLowerCase()}`)}
        </span>
      )}
    </div>
  )
}

interface Props {
  filter: AdvancedFilter
  onCancel: () => void
  onChange: (filter: AdvancedFilter) => void
  onOptionsLoaded: (options: DatacOption[]) => void
  extraOperators?: ConditionalLogicOperator[]
}

export const FilterConfig: React.FC<Props> = ({ filter, onCancel, onChange, onOptionsLoaded, extraOperators }) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(true)
  const [options, setOptions] = useState<DatacOption[]>(filter.options)
  const [search, setSearch] = useState('')
  const [searchPhrase, setSearchPhrase] = useState<string>(null)
  const [isSearching, setIsSearching] = useState(false)
  const [currentCheckboxValue, setCurrentCheckboxValue] = useState<string[]>(null)
  const intl = useScopedIntl('')

  useEffect(() => {
    setSearchPhrase(null)
    if (filter.type === QuestionType.File && !filter.selection) {
      onOperatorChange(ConditionalLogicOperator.NotEmpty)
      return
    }
    if (!filter.getOptions || filter.options !== null) {
      setIsLoadingOptions(false)
      return
    }
    setIsLoadingOptions(true)
    filter.getOptions(searchPhrase, {
      onSuccess: (options: DatacOption[]) => {
        setIsLoadingOptions(false)
        onOptionsLoaded(options)
        setOptions(options)
      }
    })
  }, [filter])

  useEffect(() => {
    setCurrentCheckboxValue(filter?.selection?.value)
  }, [filter?.selection?.value])

  useEffect(() => {
    if (searchPhrase === null || !filter.getOptions) {
      return
    }
    setIsSearching(true)
    filter.getOptions(searchPhrase, {
      onSuccess: (foundOptions: DatacOption[]) => {
        setIsSearching(false)
        const existingValues = options.map(option => option.value)
        const newOptions = [...options, ...foundOptions.filter(option => !existingValues.includes(option.value))]
        onOptionsLoaded(newOptions)
        setOptions(newOptions)
      }
    })
  }, [searchPhrase])

  const onSearchChange = useCallback(
    debounce((value: string) => {
      setSearchPhrase(value)
    }, 1000),
    []
  )

  const onOperatorChange = (operator: ConditionalLogicOperator) => {
    onChange({
      ...filter,
      selection: {
        ...(filter.selection || { value: null }),
        operator
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onValueChange = (value: any) => {
    onChange({
      ...filter,
      selection: {
        ...(filter.selection || {
          operator: getDefaultOperator(filter?.type)
        }),
        value
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCheckboxChange = (e: any, parentValue?: string) => {
    const { value, checked } = e.target
    const children = filter.options.find(o => o.value == value)?.options?.map(o => o.value)
    const siblings = filter.options.find(o => o.value == parentValue)?.options?.map(o => o.value)

    if (checked) {
      const newValue = [
        ...(currentCheckboxValue?.filter(v => v !== parentValue) || []),
        ...(parentValue ? [parentValue] : []),
        ...(children || []),
        value
      ]
      onValueChange(newValue)
      return
    }

    const newValue = currentCheckboxValue
      .filter(v => ![...(children || []), value].includes(v))
      .filter(v => currentCheckboxValue?.filter(c => siblings?.includes(c))?.length !== 1 || v !== parentValue)
    onValueChange(newValue)
  }

  let operatorOptions = [ConditionalLogicOperator.Equal, ConditionalLogicOperator.NotEqual]
  if (numberQuestionsTypes.includes(filter.type)) {
    operatorOptions = [
      ...operatorOptions,
      ConditionalLogicOperator.Greater,
      ConditionalLogicOperator.GreaterOrEqual,
      ConditionalLogicOperator.Lower,
      ConditionalLogicOperator.LowerOrEqual,
      ConditionalLogicOperator.Between
    ]
  }
  if (filter.type === QuestionType.Text) {
    operatorOptions = [
      ConditionalLogicOperator.Contains,
      ConditionalLogicOperator.NotContains,
      ConditionalLogicOperator.StartsWith,
      ConditionalLogicOperator.EndsWith
    ]
  }
  if (filter.type === QuestionType.DateTime) {
    operatorOptions = [
      ConditionalLogicOperator.Equal,
      ConditionalLogicOperator.Before,
      ConditionalLogicOperator.EqualOrBefore,
      ConditionalLogicOperator.After,
      ConditionalLogicOperator.EqualOrAfter,
      ConditionalLogicOperator.Between
    ]
  }
  if (filter.type === QuestionType.File) {
    operatorOptions = []
  }
  operatorOptions = [...operatorOptions, ...(filter.main ? [] : extraOperators || [])]
  return (
    <div className="advanced-filters__dropdown__config" data-filter-name={filter.name}>
      <div className="advanced-filters__dropdown__config__header">
        <button className="raw advanced-filters__dropdown__config__header__back" onClick={onCancel} type="button">
          <DatacIcon name="chevronLeft" />
        </button>
        <span className="advanced-filters__dropdown__config__header__title">{filter.label}</span>
        <span className="advanced-filters__dropdown__config__header__variable">{filter.sublabel}</span>
      </div>
      {[QuestionType.Number, QuestionType.Calcul, QuestionType.Slider, QuestionType.Rating].includes(filter.type) && (
        <div className="advanced-filters__dropdown__config__value advanced-filters__dropdown__config__value--number">
          <OperatorDropdown
            current={filter.selection?.operator || ConditionalLogicOperator.Equal}
            options={operatorOptions}
            onChange={onOperatorChange}
          />
          {!noInputOperators.includes(filter.selection?.operator) &&
            filter.selection?.operator !== ConditionalLogicOperator.Between && (
              <InputNumber
                className="ant-input ant-input-lg"
                onChange={onValueChange}
                value={filter.selection?.value}
              />
            )}
          {!noInputOperators.includes(filter.selection?.operator) &&
            filter.selection?.operator === ConditionalLogicOperator.Between && (
              <div className="advanced-filters__dropdown__config__value__between">
                <InputNumber
                  className="ant-input ant-input-lg"
                  onChange={value => onValueChange([value, filter.selection?.value?.[1] || 0])}
                  value={filter.selection?.value?.[0]}
                />
                -
                <InputNumber
                  className="ant-input ant-input-lg"
                  onChange={value => onValueChange([filter.selection?.value?.[0] || 0, value])}
                  value={filter.selection?.value?.[1]}
                />
              </div>
            )}
        </div>
      )}
      {selectQuestionsTypes.includes(filter.type) && (
        <div className="advanced-filters__dropdown__config__value advanced-filters__dropdown__config__value--select">
          <OperatorDropdown
            current={filter.selection?.operator || ConditionalLogicOperator.Equal}
            options={operatorOptions}
            onChange={onOperatorChange}
          />
          {!noInputOperators.includes(filter.selection?.operator) && (
            <DatacLoading isLoading={isLoadingOptions}>
              {filter.getOptions && (
                <Input.Search
                  value={search}
                  onChange={e => {
                    setSearch(e.currentTarget.value)
                    onSearchChange(e.currentTarget.value)
                  }}
                  placeholder={intl('common.search')}
                  loading={isSearching}
                />
              )}
              <Checkbox.Group value={currentCheckboxValue}>
                {(options || [])
                  .filter(
                    option =>
                      option.label.toString().toLowerCase().includes(search.toLowerCase()) ||
                      option.sublabel.toString().toLowerCase().includes(search.toLowerCase())
                  )
                  .map(option => {
                    const checkedChildren = option.options?.filter(o => currentCheckboxValue?.includes(o.value)).length
                    const isIndeterminate =
                      option.options?.length && checkedChildren > 0 && checkedChildren < option.options.length

                    return (
                      <span key={option.value}>
                        <Checkbox
                          onChange={onCheckboxChange}
                          value={option.value}
                          className={filter.showCheckbox ? 'show-checkbox' : ''}
                          indeterminate={isIndeterminate || undefined}
                        >
                          {option.label}
                          {option.sublabel && <small>{option.sublabel}</small>}
                        </Checkbox>
                        {option.options?.length > 0 &&
                          option.options.map(subOption => (
                            <Checkbox
                              className={classNames('advanced-filters__dropdown__config__value__sub-option', {
                                'show-checkbox': filter.showCheckbox
                              })}
                              key={subOption.value}
                              value={subOption.value}
                              onChange={e => onCheckboxChange(e, option.value)}
                            >
                              {subOption.label}
                              {subOption.sublabel && <small>{subOption.sublabel}</small>}
                            </Checkbox>
                          ))}
                      </span>
                    )
                  })}
              </Checkbox.Group>
            </DatacLoading>
          )}
        </div>
      )}
      {[QuestionType.Text].includes(filter.type) && (
        <div className="advanced-filters__dropdown__config__value advanced-filters__dropdown__config__value--text">
          <OperatorDropdown
            current={filter.selection?.operator || ConditionalLogicOperator.Contains}
            options={operatorOptions}
            onChange={onOperatorChange}
          />
          {!noInputOperators.includes(filter.selection?.operator) && (
            <Input
              className="ant-input ant-input-lg"
              onChange={e => onValueChange(e.target.value)}
              value={filter.selection?.value}
            />
          )}
        </div>
      )}
      {filter.type === QuestionType.DateTime && (
        <div className="advanced-filters__dropdown__config__value advanced-filters__dropdown__config__value--date">
          <OperatorDropdown
            current={filter.selection?.operator || ConditionalLogicOperator.Equal}
            options={operatorOptions}
            onChange={onOperatorChange}
          />
          {!noInputOperators.includes(filter.selection?.operator) && (
            <FilterConfigCalendar
              onChange={onValueChange}
              value={filter.selection?.value}
              operator={filter.selection?.operator || ConditionalLogicOperator.Equal}
            />
          )}
        </div>
      )}
      {filter.type === QuestionType.File && (
        <div className="advanced-filters__dropdown__config__value advanced-filters__dropdown__config__value--file">
          <OperatorDropdown
            current={filter.selection?.operator || ConditionalLogicOperator.NotEmpty}
            options={operatorOptions}
            onChange={onOperatorChange}
          />
        </div>
      )}
    </div>
  )
}
