import { Feature } from '../../../Feature'
import { BuilderImageConfig } from '../../../file'
import { ConditionalRule, RemoteConditionalRule } from '../conditionalLogic'
import { RemoteValidationRule, ValidationRule } from '../validationRules'

export enum QuestionType {
  Text = 'TEXT',
  Checkbox = 'CHECK',
  Radio = 'RADIO',
  DateTime = 'DATETIME',
  Number = 'NUMBER',
  File = 'UPLOAD_FILES',
  Rating = 'RATING',
  Calcul = 'CALCUL',
  Dropdown = 'DROPDOWN',
  Slider = 'SLIDER',
  Lsa = 'LSA'
}

export const conditionalQuestionsTypes = [
  QuestionType.Radio,
  QuestionType.Calcul,
  QuestionType.Dropdown,
  QuestionType.Number,
  QuestionType.Rating,
  QuestionType.Slider,
  QuestionType.Checkbox
]

export const selectQuestionsTypes = [QuestionType.Radio, QuestionType.Dropdown, QuestionType.Checkbox]
export const numberQuestionsTypes = [QuestionType.Number, QuestionType.Calcul, QuestionType.Slider, QuestionType.Rating]

export interface QuestionFromStudy {
  referenceQuestionId: string
  referenceQuestionBuilderId: string
}

export interface RemoteQuestionFromStudy {
  builder_id: number
  builder_type: Feature
  question_id: number
}

export interface QuestionBase {
  id?: string
  tempId?: string
  title: string
  description?: string
  descriptionImage?: BuilderImageConfig
  config: {}
  variableName?: string
  conditionalLogic?: ConditionalRule[]
  reminderQuestions?: QuestionFromStudy[]
  excludeFromExport?: boolean
  hideInEcrf?: boolean
  visibleForRoles?: string[]
  editableForRoles?: string[]
  required: boolean
  type: QuestionType
  repeatedMeasure?: string
  builderType?: Feature
  dataValidation?: { validationRules?: ValidationRule[] }
  showInSurvey: boolean
  showInProfile: boolean
  isGlobal: boolean
}

export interface RemoteQuestionBase {
  id: string
  title: string
  description?: string
  description_image?: string
  order: number
  subsection: string
  variable: string
  variable_full: string
  conditional_logic?: RemoteConditionalRule
  reminder_questions?: RemoteQuestionFromStudy[]
  exclude_from_export: boolean
  hide_in_ecrf: boolean
  visible_for_roles: string[]
  editable_for_roles: string[]
  required: boolean
  repeated_measure?: string
  repeated_measure_id?: string
  builder_type?: Feature
  validation_rules?: RemoteValidationRule[]
  show_in_survey: boolean
  show_in_profile: boolean
  invalid: boolean
  is_global: boolean
}
